.main-menu-logo {
    width: 110px;
}
@media (min-width: 768px) {
    .main-menu-logo {
        width: 140px;
    }
}

.ant-form-item-label, label {
  font-size: .7rem;
  font-weight: 400;
  line-height: 2;
}

.sidebar-menu {
  transition: all .2s ease-in-out;
  border-radius: 10px;
  background: white;
}
.sidebar-menu:hover, .sidebar-menu-active {
  background: linear-gradient(90deg, #61E58F 0%, #39B766 51.04%, #128B3E 100%);
}
.sidebar-menu {
  color: black;
}
.sidebar-menu:hover, .sidebar-menu-active {
  color: white;
}
.sidebar-menu:hover > div > div:last-child, .sidebar-menu-active > div > div:last-child {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(168deg) brightness(104%) contrast(10000%);
}
.sidebar-menu > div:nth-child(1) > div:nth-child(1) {
  background-color: #EDEDED;
  padding: 6px;
  border-radius: 50%;
  box-shadow: inset 0px 3px 0px #293d3b1a;
}
.sidebar-menu > div:nth-child(1) > div:nth-child(1) > span {
  filter: invert(55%) sepia(0%) saturate(3482%) hue-rotate(63deg) brightness(129%) contrast(81%);
}
.sidebar-menu:not(:last-child) {
    margin-bottom: 10px;
}

.sidebar-menu .parents, .sidebar-menu .child div {
    cursor: pointer;
    font-size: 13px;
}
.sidebar-menu .child  {
    -webkit-animation: fadeHeight .6s;
    animation: fadeHeight .6s;
    overflow: hidden;
}

/* latest design */
.sidebar-menu-2 {
  transition: all .2s ease-in-out;
  background: #232C35;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  /* box-shadow: rgba(142, 142, 142, 0.43) 0px 1px 0px 0px; */
}
.sidebar-menu-2 > div:first-child:hover, .sidebar-menu-2-active {
  background: linear-gradient(263deg, #0DBF7B 1.82%, #0DBF7B 99.02%);
  border-radius: 6px;
}
.sidebar-menu-2 {
  color: white;
}
.sidebar-menu-2 > div:nth-child(1) > div:nth-child(1) {
  padding: 3px;
}
.sidebar-menu-2 .parents, .sidebar-menu-2 .child div {
    cursor: pointer;
    color: #B1BAC7;
}
.sidebar-menu-2 .child  {
  transition: all .2s ease-in-out;
  opacity: 0;
  height: 0;
  min-height: 0;
}
.sidebar-menu-2 .child div {
  transition: all .2s ease-in-out;
}
.sidebar-menu-2 .child div:hover div {
  color: #ffffff;
}
.sidebar-menu-2 .active-child {
  opacity: 1;
  min-height: 44px;
  height: auto;
}
@keyframes fadeHeight {
  from { 
      opacity: 0;
  }
    to { 
      opacity: 1;
    }
}
.sidebar-menu-background {
  transition: all .2s ease-in-out;
  background: #232C35;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
}
.sidebar-menu-background > div:first-child {
  transition: all .2s ease-in-out;
}
.sidebar-menu-background > div:first-child:hover, .sidebar-menu-background-active {
  opacity: .75;
}
.sidebar-menu-background .parents, .sidebar-menu-background .child div {
  cursor: pointer;
  font-size: 13px;
}

.side-menu-2-country {
  position: relative;
  padding-left: 12px;
  padding-right: 12px;
}
.side-menu-2-country:not(:last-child)::after {
  content: "";
  width: 90%;
  position: absolute;
  bottom: 0;
  display: block;
  border-bottom: solid 1px #EDEDED;
}
.side-menu-2-country:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, .6);
}
/* latest design */


.sidebar-menu-back, .sidebar-menu-back-open {
  transition: all .2s ease-in-out;
}
.sidebar-menu-back:hover {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(168deg) brightness(104%) contrast(102%);
}

.sidebar-menu-back-open {
  background: linear-gradient(90deg, #61E58F 0%, #39B766 51.04%, #128B3E 100%);
}
.sidebar-menu-back-open > span {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(333deg) brightness(102%) contrast(102%);
} 
.sidebar-menu-back-open:hover {
  background: white;
}
.sidebar-menu-back-open:hover > span {
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(6deg) brightness(20%) contrast(118%);
}

.inner-shadow {
  box-shadow: inset 0px 3px 0px #293d3b1a;
}


.button-secondary {
  transition: all .2s ease-in-out;
  border-radius: 6px;
  background: #2F394C;
}
.button-secondary p, .button-secondary span {
  transition: all .2s ease-in-out;
  color: white;
}
.button-secondary:hover {
  border-bottom: none;
}
.button-secondary:hover > p, .button-secondary:hover > span, .button-secondary:hover > div > span {
  opacity: .7;
}

.button-primary {
  transition: all .2s ease-in-out;
  border-radius: 6px;
  background: linear-gradient(0deg, #12d389 18.82%, #0DBF7B 70.02%);

}
.button-primary > div > p, .button-primary > div > span {
  transition: all .2s ease-in-out;
  color: white;
}
.button-primary:hover {
  border-bottom: none;
}
.button-primary:hover > div > p, .button-primary:hover > div > span {
  opacity: .7;
}

.button-default {
  transition: all .2s ease-in-out;
  background: #ffffff;
  border-radius: 6px;
}
.button-default > p, .button-default > span,
.button-default > div > p, .button-default > div > span {
  transition: all .2s ease-in-out;
  color: black;
}
.button-default:hover {
  background: linear-gradient(263deg, #0DBF7B 1.82%, #0DBF7B 99.02%);
  border-bottom: solid #1FEC9E 2px;
}
.button-default:hover > p, .button-default:hover > span {
  color: white;
}

.button-secondary-transparent {
  transition: all .2s ease-in-out;
  border: solid #28AE60 2px;
  background: transparent;
}
.button-secondary-transparent p, .button-secondary-transparent span {
  color: #28AE60;
}
.button-secondary-transparent:hover {
  background: #28AE60;
}
.button-secondary-transparent:hover > p, .button-secondary-transparent:hover > span {
  color: white;
}
.button-secondary-transparent:hover .icon {
  filter: invert(0%) sepia(4%) saturate(7447%) hue-rotate(191deg) brightness(1000%) contrast(100%);
}

.button-nav-size {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
}
@media (min-width: 768px) {
    .button-nav-size {
        width: 120px;
    }
}

.button-tab {
  transition: all .2s ease-in-out;
  background: #2F394C;
}
.button-tab p, .button-tab span {
  transition: all .2s ease-in-out;
  color: white;
}
.button-tab:hover, .button-tab-active {
  background: linear-gradient(0deg, #12d389 18.82%, #0DBF7B 70.02%);
}
.button-tab > div > p, 
.button-tab > div > span, 
.button-tab-active > div > p, 
.button-tab-active > div > span
{
  transition: all .2s ease-in-out;
  color: white;
}

.input-nav-size {
    width: 160px;
}

.select-primary {
    background-color: white;
    color: black;
    padding-left: 12px;
    padding-right: 12px;
    border: none;
    appearance: none;
    background-image: url("/dropdown-arrow.png");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
}

.input-primary, input {
    background: #415768;
    padding-left: 14px;
    padding-right: 12px;
    border: none;
}
select {
  background: #415768;
}
input.large {
  font-weight: 600;
  font-size: 14px;
}

.search-input .ant-input-wrapper.ant-input-group {
  background-color: #415768;
  border-radius: 6px;
  overflow: hidden;
}
.search-input input{
  background-color: #415768;
  padding-left: 12px;
}
.search-input input:focus{
  outline: none;
}
.search-input .ant-input-group-addon > button {
  background-color: #28AE60;
  border-radius: 6px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: solid #1FEC9E 2px;
}
.search-input .ant-input-group-addon > button svg {
  filter: invert(90%) sepia(99%) saturate(29%) hue-rotate(195deg) brightness(107%) contrast(99%);
}

.search-input .ant-input-group-addon > button {
  transition: all .2s ease-in-out;
  border: solid #28AE60 2px;
  background: linear-gradient(263deg, #0DBF7B 1.82%, #0DBF7B 99.02%);
}
.search-input .ant-input-group-addon > button:hover {
  border-bottom: none;
}
/* .search-input .ant-input-group-addon > button:hover svg {
  filter: unset;
} */

.home-search-input {
  border: solid 1px #28AE60;
}

.bg-white-addonBefore input {
  background-color: #415768;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;;
}
.bg-white-addonBefore input:focus{
  outline: none;
}

.input-addonAfter .ant-input-group-addon{
  display: block;
  background-color: #2F394C;
}
.input-addonAfter input {
  background-color: #1A242D;
  border: none;
  width: 100%;
  padding-top: .6rem;
  padding-bottom: .6rem;
}
.input-addonAfter .ant-input-wrapper.ant-input-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.input-addonAfter .ant-input-group-addon {
  padding: 10px;
  font-weight: 400;
}
.input-addonAfter input:focus{
  outline: none;
}
.input-addonAfter input::-webkit-outer-spin-button,
.input-addonAfter input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.input-addonAfter input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.footer-ul div {
    color: #F29D1F;
}

.footer-url {
    color: #F29D1F;
}

.footer-intro-header {
    color: #F29D1F;
    font-weight: 700;
    margin-top: 0.5rem; /* 8px */
    margin-bottom: 0.5rem; /* 8px */
}

.footer-intro-paragraph {
    margin-bottom: 0.7rem; /* 8px */
}

.footer-intro-last-paragraph {
    margin-bottom: 1rem; /* 8px */
}

.footer-intro-paragraph > a{
    color: #F29D1F;
    text-decoration-line: underline;
    cursor: pointer;

}

.footer-intro-paragraph > span{
  color: #F29D1F;
  text-decoration-line: underline;
  cursor: pointer;

}

.footer-intro-last-paragraph > a{
    color: #F29D1F;
    text-decoration-line: underline;
    cursor: pointer;
}


@media (max-width: 768px) {
    .footer-intro-header {
        color: #F29D1F;
        font-weight: 900;
        margin-top: 0.5rem; /* 8px */
        margin-bottom: 0.5rem; /* 8px */
        text-align: start;
        font-size: 1rem; /* 16px */
line-height: 1.5rem; /* 24px */
    }
    
    .footer-intro-paragraph {
        margin-bottom: 0.7rem; /* 8px */
        text-align: start;
    }
    
    .footer-intro-last-paragraph {
        margin-bottom: 1rem; /* 8px */
        text-align: start;
    }
}

.border-inputborder {
  transition: all .2s ease-in-out;
  border: solid 2px #28AE60 !important;
}
.border-inputborder:hover, .border-inputborder-active {
  background-color: #1FEC9E !important;
  color: black !important;
}
.border-inputborder-secondary {
  transition: all .2s ease-in-out;
  background-color: #28AE60 !important;
  border: solid 2px #28AE60 !important;
}
.border-inputborder-secondary:hover {
  background-color: rgba(0, 0, 0, .2) !important;
}

.custom-pagination .ant-pagination-item a {
  transition: color 0.3s;
}

.custom-pagination .ant-pagination-item:hover a {
  color: #23c615;
}

.custom-pagination .ant-pagination-item-active a {
  color: #1FEC9E !important; 
  font-weight: bold; 
  text-decoration: underline;
}

.bethistory-table .ant-table-tbody > tr  {
  border: none;
}

.carousel .control-dots {
    text-align: left;
    padding-left: 18px;
    margin-bottom: 4px;
    margin-left: 10px;
}
@media (max-width: 768px) {
    .carousel .control-dots {
        margin-left: 5px;
    }
}

.carousel .control-dots .dot {
    margin: 0 2px;
}

.provider-slider-wrap {
    /* display: flex; */
    flex-wrap: nowrap;
    overflow-x: auto;
}

.provider-slider {
    display: flex;
    justify-content: center;
    flex: 0 0 auto;
    width: 80px;
    height: 80px;
}

.provider-slider span {
    font-size: 11px;
}

.home-top-banner-wrap {
    position: relative;
    width: 100%;
}

.home-top-banner-wrap .banner_wrap {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    width: 106%;
    /* width: calc(100% + 40px); */
    height: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    pointer-events: none;

    animation-name: banners-move-bg;
    animation-duration: 30s;
    animation-play-state: running;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    object-position: 50% 50%;
    right: -20px;
    left: -20px;
}

.home-top-banner-wrap .banner_wrap img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-position: inherit;
    object-position: inherit;
    border-radius: inherit;
    object-fit: cover;
}

.home-top-banner-wrap .layer1_wrap {
    position: absolute;
    right: auto;
    left: 10%;
    bottom: 0;

    animation-name: banners-move-front;
    animation-duration: 30s;
    animation-play-state: running;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.home-top-banner-wrap .banner_wrap span img,
.home-top-banner-wrap .layer1_wrap span img,
.home-top-banner-wrap .layer2_wrap span img,
.home-top-banner-wrap .layer3_wrap span img{
    position: relative !important;
    height: 100% !important;
    width: 100% !important;
}

.home-top-banner-wrap .banner_wrap span, 
.home-top-banner-wrap .layer1_wrap span, 
.home-top-banner-wrap .layer2_wrap span, 
.home-top-banner-wrap .layer3_wrap span {
    position: relative !important;
}

.home-top-banner-wrap .layer2_wrap {
    position: absolute;
    right: auto;
    left: 10%;
    bottom: 0;

    animation-name: banners-move-front-2;
    animation-duration: 30s;
    animation-play-state: running;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.home-top-banner-wrap .layer3_wrap {
    position: absolute;
    /* right: auto; */
    left: auto;
    bottom: -7.5%;
}

@keyframes banners-move-bg {
    0% {
        transform: translateZ(0) rotate(.0001deg);
    }

    25% {
        transform: translate3d(-20px, 0, 0) rotate(.0001deg);
    }

    50% {
        transform: translateZ(0) rotate(.0001deg);
    }

    75% {
        transform: translate3d(20px, 0, 0) rotate(.0001deg);
    }

    100% {
        transform: translateZ(0) rotate(.0001deg);
    }
}

@keyframes banners-move-front {
    0% {
        transform: translateZ(0) rotate(.0001deg);
    }

    25% {
        transform: translate3d(10px, 0, 0) rotate(.0001deg);
    }

    50% {
        transform: translateZ(0) rotate(.0001deg);
    }

    75% {
        transform: translate3d(-6px, 0, 0) rotate(.0001deg);
    }

    100% {
        transform: translateZ(0) rotate(.0001deg);
    }
}

@keyframes banners-move-front-2 {
    0% {
        transform: translateZ(0) rotate(.0001deg);
    }

    25% {
        transform: translate3d(-10px, 0, 0) rotate(.0001deg);
    }

    50% {
        transform: translateZ(0) rotate(.0001deg);
    }

    75% {
        transform: translate3d(6px, 0, 0) rotate(.0001deg);
    }

    100% {
        transform: translateZ(0) rotate(.0001deg);
    }
}

@media (min-width: 768px) {
  .menu-faq:hover, .menu-faq-active {
    background: #28AE60;
  }
  .menu-faq:hover p, .menu-faq:hover span, .menu-faq-active p, .menu-faq-active span {
    color: white;
  }
}

.selectedLive {
    cursor: pointer;
    padding: 8px;
    margin-bottom: 4px;
}
.selectedLive.active {
    background-color: #425C76;
    border-radius: 8px;
}


.home-carousel .control-dots {
    /* text-align: right !important; */
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
  .home-carousel .control-dots li.dot {
    margin: 0 2px !important;
    width: 6px;
    height: 6px;
    border-radius: 12px !important;
    background-color: #BFBFBF !important;
    opacity: 1 !important;
    box-shadow: none !important;
  }
  .home-carousel .control-dots li.dot.selected {
    background-color: #F29D1F !important;
  }

  .home-sport-carousel button.control-arrow.control-prev,
  .home-sport-carousel button.control-arrow.control-next {
    background-color: #232C35;
    z-index: 10;
    height: 34px;
    width: 34px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    padding: 0;
    margin: 0;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .custom-carousel-buttons {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
    z-index: 10;
    display: none;
  }
  @media (min-width: 768px) {
    .custom-carousel-buttons {
      display: block;
    }
  }
  .custom-carousel-buttons button.control-arrow.control-prev {
    left: -20px;
  }
  .custom-carousel-buttons button.control-arrow.control-next {
    right: -20px;
  }

  .navbar-bottom {
    position: fixed;
    bottom: -1px;
    width: 100%;
    background-color: #232C35;
    height: 78px;
    z-index: 30;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    /* box-shadow: 0px -2px 0px 0px #8E8E8E6E; */
  }
  .navbar-child {
    /* position: absolute;
    bottom: 8px; */
    width: 100%;
    /* left: 0; */
    height: 100%;
  }
  .navbar-child > div {
    cursor: pointer;
    /* display: flex;
    flex-direction: column; */
  }
  .navbar-child div > div {
    /* width: 20px; */
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mobile-menu {
    background-color: transparent ;
  }
  .nav-inactive div:first-child, .nav-inactive p {
    filter: invert(51%) sepia(0%) saturate(24%) hue-rotate(134deg) brightness(99%) contrast(87%);
  }
  .nav-active div:first-child {
    filter: invert(62%) sepia(48%) saturate(399%) hue-rotate(91deg) brightness(150%) contrast(88%) !important;
  }
  .nav-active p {
    filter: unset !important;
  }
    
    /* .home-hotgame-wrap {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
    } */

    /* .home-hotgame {
        flex: 0 0 auto;
        width: 175px;
    } */

  /* .home-hotgame-wrap > div {
    display: inline-block;
  } */
  .home-hotgame-wrap > div:nth-child(1) {
    width: 29%;
  }
  /* .home-hotgame-wrap > div:nth-child(2) {
    width: 75%;
  } */
  @media (min-width: 768px) {
    .home-hotgame-wrap > div:nth-child(2) {
        width: 74.6%;
    }
}
.hotgame-carousel .thumbs-wrapper {
    margin: 0;
}
.hotgame-carousel .control-dots {
    margin: 0 10px 2px 10px;
}

/* more samples */
/* https://cssloaders.github.io/ */
/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  --b: 3px; 
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 1px;
  background: conic-gradient(#232c34 10%,#1abf7f) content-box;
  -webkit-mask:
    repeating-conic-gradient(#0000 0deg,#000 1deg 20deg,#0000 21deg 36deg),
    radial-gradient(farthest-side,#0000 calc(100% - var(--b) - 1px),#000 calc(100% - var(--b)));
  -webkit-mask-composite: destination-in;
          mask-composite: intersect;
  animation:l4 .6s infinite steps(10);
  display: block;
  margin-top: -37px;
}
@media (min-width: 768px) {
  .loader {
    width: 60px;
    --b: 4px; 
    margin-top: -43px;
  }
}
@keyframes l4 {to{transform: rotate(1turn)}}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 

@keyframes spinner-spin {
  100% { transform: rotate(360deg)}
}
@keyframes spinner-fade {
  25%, 75% { opacity: 0.1}
  50% { opacity: 1 }
}
  
.loader-bottom {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: #F29D1F;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 

/* HTML: <div class="loader"></div> */
.button-loader {
  width: 30px;
  aspect-ratio: 4;
  background: radial-gradient(circle closest-side,#ffffff 90%,#0000) 0/calc(100%/3) 100% space;
  clip-path: inset(0 100% 0 0);
  animation: l1 1s steps(4) infinite;
}
@keyframes l1 {to{clip-path: inset(0 -34% 0 0)}}

.games-container {
    width: 100%;
    position: relative;
    background-size: cover;
    background-position: bottom;
}

.pincode-input-container {
    display: flex;
    justify-content: center;
}

.livechat-custom {
    max-width: 98%;
    width: 98%;
    max-height: 600px;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
}

@media (min-width: 768px) {
    .livechat-custom {
        max-width: 400px;
        width: 400px;
        max-height: 600px;
        height: 100%;
        border-radius: 8px;
        overflow: hidden;
    }   
}

.livechat-custom-landscape {
    max-width: 98%;
    width: 50%;
    max-height: 600px;
    height: 90%;
    border-radius: 8px;
    overflow: hidden;
}

.cut-text-soccer { 
    text-overflow: ellipsis;
    overflow: hidden; 
    /* width: 74px;  */
    height: 1.3em; 
    white-space: nowrap;
    margin: auto;
  }

  .cut-text { 
    text-overflow: ellipsis;
    overflow: hidden; 
    width: 50px; 
    height: 1.2em; 
    white-space: nowrap;
    margin: auto;
  }

  @media (min-width: 400px) {
    .cut-text { 
        width: 70px; 
      }
  }

  @media (min-width: 500px) {
    .cut-text { 
        width: 80px; 
      }
  }

  @media (min-width: 768px) {
    .cut-text { 
        width: 70px; 
      }
  }

  @media (min-width: 1000px) {
    .cut-text { 
        width: 90px; 
      }
  }

  .cut-text-game {
    text-overflow: ellipsis;
    overflow: hidden; 
    max-width: 90%; 
    /* height: 1.2em;  */
    white-space: nowrap;
    /* margin: auto; */
  }
  /* @media (min-width: 400px) {
    .cut-text-game { 
        width: 80px; 
      }
  }
  @media (min-width: 500px) {
    .cut-text-game { 
        width: 100px; 
      }
  }
  @media (min-width: 768px) {
    .cut-text-game { 
        width: 80px; 
      }
  }
  @media (min-width: 1000px) {
    .cut-text-game { 
        width: 90px; 
      }
  } */

  .cut-text-full {
    text-overflow: ellipsis;
    overflow: hidden; 
    width: 100%; 
    height: 1.3em; 
    white-space: nowrap;
    margin: auto;
  }

  .cut-text-sport-match {
    text-overflow: ellipsis;
    overflow: hidden; 
    width: 90%; 
    display: inline-block;
    height: 1.3em; 
    white-space: nowrap;
    margin: auto;
  }

  .collapsible {
    cursor: pointer;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    padding: 6px 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .collapsible > div > div > span {
    font-size: 12px;
    font-weight: 500;
  }

  .collapsible_Football {
    background: #0075FF;
  }
  .collapsible_Cricket {
    background: #8000FF;
  }
  .collapsible_Basketball {
    background: #FF9900;
  }
  
  .sport-badge span {
    font-size: 7px;
  }
  .sport-badge sup {
    padding: 0 0px;
    min-width: 14px;
    height: 14px;
    line-height: 1;
  }
  @media (min-width: 768px) {
    .sport-badge span {
        font-size: 10px;
    }
    .sport-badge sup {
    padding: 0 4px;
    }
  }
  .content {
    /* padding: 0 4px; */
    /* max-height: 0; */
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    background-color: #1D262C;
    /* border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px; */
  }
  .content p, 
  .content span, 
  .sport-head-title
  {
    font-size: 9px;
    font-weight: 400;
  }
  @media (min-width: 768px) {
    .content p, 
    .content span,
    .sport-head-title {
        font-size: 11px;
      } 
  }

  .flex-container {
    display: flex;
    overflow-x: auto; /* Enable horizontal scrolling */
    max-width: 100%; /* Ensure the container takes the full width of the viewport */
  }

  .flex-item {
    flex: 0 0 auto; /* Allow items to shrink or grow, but not shrink to less than their content */
    margin: 0 8px;
    width: 20px; /* Set a fixed width for each item */
  }

  @media (min-width: 768px) {
    .flex-item {
        width: 30px; 
      }
  }

  .flex-item-sport {
    flex: 0 0 auto; 
    width: 300px; 
  }
  .flex-item-sport:not(:last-child) {
    margin-right: 10px;
  }

  .flex-item-banner {
    flex: 0 0 auto; 
    width: 254px; 
  }
  .flex-item-banner:not(:last-child) {
    margin-right: 8px;
  }
  @media (min-width: 768px) {
    .flex-item-banner {
      flex: 0 0 auto; 
      width: 461px; 
    }
    .flex-item-banner:not(:last-child) {
      margin-right: 0;
    }
  }

  .flex-item-matchbanner {
    flex: 0 0 auto; 
    width: 254px; 
  }
  .flex-item-matchbanner:not(:last-child) {
    margin-right: 8px;
  }
  @media (min-width: 768px) {
    .flex-item-matchbanner {
      width: 300px;
    }
  }

  .flex-item-playbanner {
    flex: 0 0 auto; 
    width: 123px; 
  }
  .flex-item-playbanner:not(:last-child) {
    margin-right: 8px;
  }
  @media (min-width: 768px) {
    .flex-item-playbanner {
      width: 146px;
    }
  }

  .flex-item-game {
    flex: 0 0 auto; 
    width: 130px; 
  }
  @media (min-width: 768px) {
    .flex-item-game {
      width: 140px; 
    }
  }

  .flex-item-provider {
    flex: 0 0 auto; 
    width: 150px; 
  } 
  .flex-item-provider:not(:last-child) {
    margin-right: 8px;
  }
  .flex-item-game-banner {
    flex: 0 0 auto; 
    width: 310px; 
  }
  .flex-item-game-banner:not(:last-child) {
    margin-right: 8px;
  }
  @media (min-width: 768px) {
    .flex-item-game-banner {
      width: 785px; 
    }
  }

  .flex-item-bonus {
    flex: 0 0 auto; 
    width: 180px; 
  }

  .flex-item-promotion {
    flex: 0 0 auto; 
  }

  .flex-item-tab {
    flex: 0 0 auto;
  }

  .game-provider-wrapper {
    transition: all .2s ease-in-out;
    cursor: pointer;
  }
  .game-provider-wrapper:hover {
    background: #28AE60;
    color: white;
  }
  .game-provider-wrapper-active {
    background: #28AE60;
    color: white;
  }

  .newDashboardLineDesktop span, .newDashboardLineDesktop span img {
    position: relative !important
  }

  .newDashboardLineDesktop span img {
    height: 1px !important;
    width: 100%;
  }

  .hotgames-mobile span, .hotgames-mobile span img {
    position: relative !important
  }

  .hotgames-mobile span img {
    height: 100% !important;
    width: 100% !important;
  }

  .home-popup-banner span, 
  .home-popup-banner span img, 
  .banner-right span, 
  .banner-right span img{
    position: relative !important 
  }

  .home-popup-banner span img, 
  .banner-right span img{
    height: 320px !important;
    width: 100% !important;
  }

  .right-banner-div {
    height: 320px !important;
  }

.profile-tab {
  transition: all .2s ease-in-out;
  background-color: #232C35;
}
.profile-tab:hover {
  background: linear-gradient(263deg, #0DBF7B 1.82%, #0DBF7B 99.02%);
  color: white;
}
.profile-tab.profile-active {
  background: linear-gradient(263deg, #0DBF7B 1.82%, #0DBF7B 99.02%);
  color: white;
}
.profile-tab:hover img, .profile-active img {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(168deg) brightness(1000%) contrast(100%);
}

.bank-tab {
  transition: all .2s ease-in-out;
  background: linear-gradient(263deg, #0DBF7B 1.82%, #0DBF7B 99.02%);
  color: white;
}
.bank-tab:hover {
  background-color: white;
  color: black;
}

.menu-get-bonus {
  background-color: #EDEDED;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
.bonug-img {
  width: 17px;
  height: 17px;
}

.vip-tab {
  transition: all .2s ease-in-out;
  position: relative;
  border: 1px solid #8E8E8E6E;
  border-radius: 26px;
}
.vip-tab:hover, 
.vip-tab-active {
  /* content: "";
  background: linear-gradient(90deg, #61E58F 0%, #39B766 51.04%, #128B3E 100%);
  position: absolute;
  width: 100%;
  height: 4px;
  bottom: 0;
  border-radius: 4px; */
  border: 1px solid #1FEC9E;
  background: linear-gradient(0deg, rgba(26, 36, 45, 0.3), rgba(26, 36, 45, 0.3)), radial-gradient(68.06% 68.06% at 49.74% 100%, rgba(0, 255, 133, 0.2) 0%, rgba(0, 84, 13, 0) 100%);
}
.vip-tab:hover p, 
.vip-tab-active p {
  color: #1FEC9E;
}

.sport-button {
  transition: all .2s ease-in-out;
  background: #EDEDED;
}
.sport-button p {
  transition: all .2s ease-in-out;
  color: #F72543;
}
.sport-button:hover {
  background: linear-gradient(263deg, #0DBF7B 1.82%, #0DBF7B 99.02%);
}
.sport-button:hover p {
  color: white;
}
.sport-arrow {
  position: relative;
}
.sport-arrow::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #F72543;
  position: absolute;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  bottom: -4px;
  left: 0;
}

.button-language {
  width: 67%;
  margin: auto;
}
@media (min-width: 768px) {
  .button-language {
    width: 100%;
  }
}


#checkbox {
  display: none;
}
.toggle {
  height: 25px;
  width: 60px;
  border-radius: 9999px;
  background-color: #1B232A;
  /* background-image: url(https://images.unsplash.com/photo-1469474968028-56623f02e42e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=874&q=80); */
  background-size: cover;
  position: relative;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  box-shadow: 6px 6px 10px #0000003d;
}
@media (min-width: 768px) {
  .toggle {
    height: 30px;
    width: 64px;
  }
}
/* #checkbox:checked + .toggle {
  background-image: url(https://images.unsplash.com/photo-1475924156734-496f6cac6ec1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80);
  background-size: cover;
} */

.toggle::before {
  content: "";
  height: 25px;
  width: 25px;
  position: absolute;
  left: 0;
  border-radius: 50%;
  background-color: #f4f4f4;
  transition: all 0.5s ease-in-out;
  background-image: url('/english_language_icon.png');
  background-size: cover;
}
@media (min-width: 768px) {
  .toggle::before {
    height: 30px;
    width: 30px;
  }
}
.toggle::after {
  content: "EN";
  font-weight: 600;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  text-align: right;
  right: 10px;
}
#checkbox:checked + .toggle::before {
  position: absolute;
  left: calc(100% - 33px);
  background-image: url('/portuguese_language_icon.png');
  background-size: cover;
}
#checkbox:checked + .toggle::after {
  content: "BZ";
  text-align: left;
  left: 10px;
}

.swiper-pagination-bullet-active {
  background: linear-gradient(0deg, #12d389 18.82%, #0DBF7B 70.02%) !important;
}
.swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
}
.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 2px !important;
}
.swiper-button-prev, .swiper-button-next {
  color: white !important;
  /* color: rgb(88, 190, 130) !important; */
}

.customer-checkout-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.customer-checkout-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: transparent;
  border: solid 1px rgba(255, 255, 255, .7);
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.customer-checkout-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.customer-checkout-container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.customer-checkout-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.customer-checkout-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}