:root {
    --van-rolling-text-background: inherit;
    --van-rolling-text-color: var(--van-text-color);
    --van-rolling-text-font-size: var(--van-font-size-md);
    --van-rolling-text-gap: 0px;
    --van-rolling-text-item-width: 30px;
    --van-rolling-text-item-border-radius: 0px
}

.super-win-wrap {
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(0%, -60%);
    left: 0;
    right: -16%;
    margin: auto;
  }
  @media (min-width: 768px) {
    .super-win-wrap {
      transform: translate(0%, -53%);
      right: 0%;
    }
  }
  .van-rolling-text-item__item {
    font-size: 30px;
    font-weight: 700;
    font-family: "Chakra Petch";
    /* background: linear-gradient(rgb(255, 242, 151) 34.47%, rgb(255, 255, 255) 48.32%, rgb(255, 216, 79) 64.8%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
  }
  @media (min-width: 488px) {
    .van-rolling-text-item__item {
        font-size: 40px;
    }
  }
  @media (min-width: 768px) {
    .van-rolling-text-item__item {
        font-size: 30px;
    }
  }
  @media (min-width: 1188px) {
    .van-rolling-text-item__item {
        font-size: 48px;
    }
  }
    /* ___ */
  .van-rolling-text-item--down {
    height: 50px;
  }
  .van-rolling-text-item__item {
    line-height: 50px;
  }

  .van-rolling-alignment {
    margin-left: 60px;
    margin-top: 12px;
  }
  @keyframes glow {
    from {
        text-shadow: 0 0 2px #23b546, 0 0 3px #23b546, 0 0 4px #FFD84F, 0 0 5px #FFD84F, 0 0 6px #FFD84F, 0 0 7px #FFD84F, 0 0 8px #FFD84F;
    }
    to {
        text-shadow: 0 0 3px #23b546, 0 0 4px #23b546, 0 0 5px #23b546, 0 0 6px #23b546, 0 0 7px #23b546, 0 0 8px #23b546, 0 0 10px #23b546;
    }
  }
  /* @media (min-width: 488px) {
    .van-rolling-alignment {
      margin-left: 46px;
    }
  } */
  @media (min-width: 768px) {
    .van-rolling-alignment {
      margin-left: 60px;
      margin-top: 0px;
    }
  }
  
  .van-rolling-text-item {
    margin-right: var(--van-rolling-text-gap);
    width: 20px;
    border-radius: var(--van-rolling-text-item-border-radius);
    background: var(--van-rolling-text-background);
    overflow: hidden;
  }
  @media (min-width: 488px) {
    .van-rolling-text-item {
        width: 25px;
    }
  }
  @media (min-width: 768px) {
    .van-rolling-text-item {
        width: 20px;
    }
  }
  @media (min-width: 1188px) {
    .van-rolling-text-item {
        width: 30px;
    }
  }
  .super-win-button {
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(0, -40%);
    right: -1.5%;
    width: 15%;
  }
  @media (min-width: 768px) {
    .super-win-button {
        right: 3.5%;
      }
  }

  /* .super-win-button p {
    position: absolute;
    top: 50%;
    transform: translate(0, -58%);
    right: 0;
    left: 0;
    margin: auto;
  } */
  
.jackpot_home .numbox {
    position: absolute;
    top: 34%;
    left: 25%;
    right: 25%;
    bottom: 19%;
    text-align: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

/* .jackpot_home .numbox .van-rolling-text {
    --van-rolling-text-item-width: auto;
    margin-right: .03906rem;
} */

.van-theme-dark {
    --van-text-color: #f5f5f5;
    --van-text-color-2: #707070;
    --van-text-color-3: #4d4d4d;
    --van-border-color: #3a3a3c;
    --van-active-color: #3a3a3c;
    --van-background: #000;
    --van-background-2: #1c1c1e;
    --van-background-3: #37363b
}

@-webkit-keyframes van-slide-up-enter {
    0% {
        -webkit-transform: translate3d(0,100%,0);
        transform: translate3d(0,100%,0)
    }
}

@keyframes van-slide-up-enter {
    0% {
        -webkit-transform: translate3d(0,100%,0);
        transform: translate3d(0,100%,0)
    }
}

@-webkit-keyframes van-slide-up-leave {
    to {
        -webkit-transform: translate3d(0,100%,0);
        transform: translate3d(0,100%,0)
    }
}

@keyframes van-slide-up-leave {
    to {
        -webkit-transform: translate3d(0,100%,0);
        transform: translate3d(0,100%,0)
    }
}

@-webkit-keyframes van-slide-down-enter {
    0% {
        -webkit-transform: translate3d(0,-100%,0);
        transform: translate3d(0,-100%,0)
    }
}

@keyframes van-slide-down-enter {
    0% {
        -webkit-transform: translate3d(0,-100%,0);
        transform: translate3d(0,-100%,0)
    }
}

@-webkit-keyframes van-slide-down-leave {
    to {
        -webkit-transform: translate3d(0,-100%,0);
        transform: translate3d(0,-100%,0)
    }
}

@keyframes van-slide-down-leave {
    to {
        -webkit-transform: translate3d(0,-100%,0);
        transform: translate3d(0,-100%,0)
    }
}

@-webkit-keyframes van-slide-left-enter {
    0% {
        -webkit-transform: translate3d(-100%,0,0);
        transform: translate3d(-100%,0,0)
    }
}

@keyframes van-slide-left-enter {
    0% {
        -webkit-transform: translate3d(-100%,0,0);
        transform: translate3d(-100%,0,0)
    }
}

@-webkit-keyframes van-slide-left-leave {
    to {
        -webkit-transform: translate3d(-100%,0,0);
        transform: translate3d(-100%,0,0)
    }
}

@keyframes van-slide-left-leave {
    to {
        -webkit-transform: translate3d(-100%,0,0);
        transform: translate3d(-100%,0,0)
    }
}

@-webkit-keyframes van-slide-right-enter {
    0% {
        -webkit-transform: translate3d(100%,0,0);
        transform: translate3d(100%,0,0)
    }
}

@keyframes van-slide-right-enter {
    0% {
        -webkit-transform: translate3d(100%,0,0);
        transform: translate3d(100%,0,0)
    }
}

@-webkit-keyframes van-slide-right-leave {
    to {
        -webkit-transform: translate3d(100%,0,0);
        transform: translate3d(100%,0,0)
    }
}

@keyframes van-slide-right-leave {
    to {
        -webkit-transform: translate3d(100%,0,0);
        transform: translate3d(100%,0,0)
    }
}

@-webkit-keyframes van-fade-in {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes van-fade-in {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@-webkit-keyframes van-fade-out {
    0% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@keyframes van-fade-out {
    0% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@-webkit-keyframes van-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes van-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.van-fade-enter-active {
    -webkit-animation: var(--van-duration-base) van-fade-in both var(--van-ease-out);
    animation: var(--van-duration-base) van-fade-in both var(--van-ease-out)
}

.van-fade-leave-active {
    -webkit-animation: var(--van-duration-base) van-fade-out both var(--van-ease-in);
    animation: var(--van-duration-base) van-fade-out both var(--van-ease-in)
}

.van-slide-up-enter-active {
    -webkit-animation: van-slide-up-enter var(--van-duration-base) both var(--van-ease-out);
    animation: van-slide-up-enter var(--van-duration-base) both var(--van-ease-out)
}

.van-slide-up-leave-active {
    -webkit-animation: van-slide-up-leave var(--van-duration-base) both var(--van-ease-in);
    animation: van-slide-up-leave var(--van-duration-base) both var(--van-ease-in)
}

.van-slide-down-enter-active {
    -webkit-animation: van-slide-down-enter var(--van-duration-base) both var(--van-ease-out);
    animation: van-slide-down-enter var(--van-duration-base) both var(--van-ease-out)
}

.van-slide-down-leave-active {
    -webkit-animation: van-slide-down-leave var(--van-duration-base) both var(--van-ease-in);
    animation: van-slide-down-leave var(--van-duration-base) both var(--van-ease-in)
}

.van-slide-left-enter-active {
    -webkit-animation: van-slide-left-enter var(--van-duration-base) both var(--van-ease-out);
    animation: van-slide-left-enter var(--van-duration-base) both var(--van-ease-out)
}

.van-slide-left-leave-active {
    -webkit-animation: van-slide-left-leave var(--van-duration-base) both var(--van-ease-in);
    animation: van-slide-left-leave var(--van-duration-base) both var(--van-ease-in)
}

.van-slide-right-enter-active {
    -webkit-animation: van-slide-right-enter var(--van-duration-base) both var(--van-ease-out);
    animation: van-slide-right-enter var(--van-duration-base) both var(--van-ease-out)
}

.van-slide-right-leave-active {
    -webkit-animation: van-slide-right-leave var(--van-duration-base) both var(--van-ease-in);
    animation: van-slide-right-leave var(--van-duration-base) both var(--van-ease-in)
}

.van-clearfix:after {
    display: table;
    clear: both;
    content: ""
}

.van-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.van-multi-ellipsis--l2 {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-break: anywhere;
    -webkit-box-orient: vertical
}

.van-multi-ellipsis--l3 {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    line-break: anywhere;
    -webkit-box-orient: vertical
}

.van-safe-area-top {
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top)
}

.van-safe-area-bottom {
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom)
}

.van-haptics-feedback {
    cursor: pointer
}

.van-haptics-feedback:active {
    opacity: var(--van-active-opacity)
}

[class*=van-hairline]:after {
    position: absolute;
    box-sizing: border-box;
    content: " ";
    pointer-events: none;
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
    border: 0 solid var(--van-border-color);
    -webkit-transform: scale(.5);
    -ms-transform: scale(.5);
    transform: scale(.5)
}

.van-hairline,.van-hairline--top,.van-hairline--left,.van-hairline--right,.van-hairline--bottom,.van-hairline--surround,.van-hairline--top-bottom {
    position: relative
}

.van-hairline--top:after {
    border-top-width: var(--van-border-width)
}

.van-hairline--left:after {
    border-left-width: var(--van-border-width)
}

.van-hairline--right:after {
    border-right-width: var(--van-border-width)
}

.van-hairline--bottom:after {
    border-bottom-width: var(--van-border-width)
}

.van-hairline--top-bottom:after,.van-hairline-unset--top-bottom:after {
    border-width: var(--van-border-width) 0
}

.van-hairline--surround:after {
    border-width: var(--van-border-width)
}

.van-radio-group--horizontal {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

:root {
    --van-badge-size: 16px;
    --van-badge-color: var(--van-white);
    --van-badge-padding: 0 3px;
    --van-badge-font-size: var(--van-font-size-sm);
    --van-badge-font-weight: var(--van-font-bold);
    --van-badge-border-width: var(--van-border-width);
    --van-badge-background: var(--van-danger-color);
    --van-badge-dot-color: var(--van-danger-color);
    --van-badge-dot-size: 8px;
    --van-badge-font: -apple-system-font, helvetica neue, arial, sans-serif
}

.van-badge {
    display: inline-block;
    box-sizing: border-box;
    min-width: var(--van-badge-size);
    padding: var(--van-badge-padding);
    color: var(--van-badge-color);
    font-weight: var(--van-badge-font-weight);
    font-size: var(--van-badge-font-size);
    font-family: var(--van-badge-font);
    line-height: 1.2;
    text-align: center;
    background: var(--van-badge-background);
    border: var(--van-badge-border-width) solid var(--van-background-2);
    border-radius: var(--van-radius-max)
}

.van-badge--fixed {
    position: absolute;
    -webkit-transform-origin: 100%;
    -ms-transform-origin: 100%;
    transform-origin: 100%
}

.van-badge--top-left {
    top: 0;
    left: 0;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
}

.van-badge--top-right {
    top: 0;
    right: 0;
    -webkit-transform: translate(50%,-50%);
    -ms-transform: translate(50%,-50%);
    transform: translate(50%,-50%)
}

.van-badge--bottom-left {
    bottom: 0;
    left: 0;
    -webkit-transform: translate(-50%,50%);
    -ms-transform: translate(-50%,50%);
    transform: translate(-50%,50%)
}

.van-badge--bottom-right {
    bottom: 0;
    right: 0;
    -webkit-transform: translate(50%,50%);
    -ms-transform: translate(50%,50%);
    transform: translate(50%,50%)
}

.van-badge--dot {
    width: var(--van-badge-dot-size);
    min-width: 0;
    height: var(--van-badge-dot-size);
    background: var(--van-badge-dot-color);
    border-radius: 100%;
    border: none;
    padding: 0
}

.van-badge__wrapper {
    position: relative;
    display: inline-block
}

.van-icon {
    position: relative;
    display: inline-block;
    font: 14px/1 vant-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased
}

.van-icon:before {
    display: inline-block
}

.van-icon-exchange:before {
    content: ""
}

.van-icon-eye:before {
    content: ""
}

.van-icon-enlarge:before {
    content: ""
}

.van-icon-expand-o:before {
    content: ""
}

.van-icon-eye-o:before {
    content: ""
}

.van-icon-expand:before {
    content: ""
}

.van-icon-filter-o:before {
    content: ""
}

.van-icon-fire:before {
    content: ""
}

.van-icon-fail:before {
    content: ""
}

.van-icon-failure:before {
    content: ""
}

.van-icon-fire-o:before {
    content: ""
}

.van-icon-flag-o:before {
    content: ""
}

.van-icon-font:before {
    content: ""
}

.van-icon-font-o:before {
    content: ""
}

.van-icon-gem-o:before {
    content: ""
}

.van-icon-flower-o:before {
    content: ""
}

.van-icon-gem:before {
    content: ""
}

.van-icon-gift-card:before {
    content: ""
}

.van-icon-friends:before {
    content: ""
}

.van-icon-friends-o:before {
    content: ""
}

.van-icon-gold-coin:before {
    content: ""
}

.van-icon-gold-coin-o:before {
    content: ""
}

.van-icon-good-job-o:before {
    content: ""
}

.van-icon-gift:before {
    content: ""
}

.van-icon-gift-o:before {
    content: ""
}

.van-icon-gift-card-o:before {
    content: ""
}

.van-icon-good-job:before {
    content: ""
}

.van-icon-home-o:before {
    content: ""
}

.van-icon-goods-collect:before {
    content: ""
}

.van-icon-graphic:before {
    content: ""
}

.van-icon-goods-collect-o:before {
    content: ""
}

.van-icon-hot-o:before {
    content: ""
}

.van-icon-info:before {
    content: ""
}

.van-icon-hotel-o:before {
    content: ""
}

.van-icon-info-o:before {
    content: ""
}

.van-icon-hot-sale-o:before {
    content: ""
}

.van-icon-hot:before {
    content: ""
}

.van-icon-like:before {
    content: ""
}

.van-icon-idcard:before {
    content: ""
}

.van-icon-invitation:before {
    content: ""
}

.van-icon-like-o:before {
    content: ""
}

.van-icon-hot-sale:before {
    content: ""
}

.van-icon-location-o:before {
    content: ""
}

.van-icon-location:before {
    content: ""
}

.van-icon-label:before {
    content: ""
}

.van-icon-lock:before {
    content: ""
}

.van-icon-label-o:before {
    content: ""
}

.van-icon-map-marked:before {
    content: ""
}

.van-icon-logistics:before {
    content: ""
}

.van-icon-manager:before {
    content: ""
}

.van-icon-more:before {
    content: ""
}

.van-icon-live:before {
    content: ""
}

.van-icon-manager-o:before {
    content: ""
}

.van-icon-medal:before {
    content: ""
}

.van-icon-more-o:before {
    content: ""
}

.van-icon-music-o:before {
    content: ""
}

.van-icon-music:before {
    content: ""
}

.van-icon-new-arrival-o:before {
    content: ""
}

.van-icon-medal-o:before {
    content: ""
}

.van-icon-new-o:before {
    content: ""
}

.van-icon-free-postage:before {
    content: ""
}

.van-icon-newspaper-o:before {
    content: ""
}

.van-icon-new-arrival:before {
    content: ""
}

.van-icon-minus:before {
    content: ""
}

.van-icon-orders-o:before {
    content: ""
}

.van-icon-new:before {
    content: ""
}

.van-icon-paid:before {
    content: ""
}

.van-icon-notes-o:before {
    content: ""
}

.van-icon-other-pay:before {
    content: ""
}

.van-icon-pause-circle:before {
    content: ""
}

.van-icon-pause:before {
    content: ""
}

.van-icon-pause-circle-o:before {
    content: ""
}

.van-icon-peer-pay:before {
    content: ""
}

.van-icon-pending-payment:before {
    content: ""
}

.van-icon-passed:before {
    content: ""
}

.van-icon-plus:before {
    content: ""
}

.van-icon-phone-circle-o:before {
    content: ""
}

.van-icon-phone-o:before {
    content: ""
}

.van-icon-printer:before {
    content: ""
}

.van-icon-photo-fail:before {
    content: ""
}

.van-icon-phone:before {
    content: ""
}

.van-icon-photo-o:before {
    content: ""
}

.van-icon-play-circle:before {
    content: ""
}

.van-icon-play:before {
    content: ""
}

.van-icon-phone-circle:before {
    content: ""
}

.van-icon-point-gift-o:before {
    content: ""
}

.van-icon-point-gift:before {
    content: ""
}

.van-icon-play-circle-o:before {
    content: ""
}

.van-icon-shrink:before {
    content: ""
}

.van-icon-photo:before {
    content: ""
}

.van-icon-qr:before {
    content: ""
}

.van-icon-qr-invalid:before {
    content: ""
}

.van-icon-question-o:before {
    content: ""
}

.van-icon-revoke:before {
    content: ""
}

.van-icon-replay:before {
    content: ""
}

.van-icon-service:before {
    content: ""
}

.van-icon-question:before {
    content: ""
}

.van-icon-search:before {
    content: ""
}

.van-icon-refund-o:before {
    content: ""
}

.van-icon-service-o:before {
    content: ""
}

.van-icon-scan:before {
    content: ""
}

.van-icon-share:before {
    content: ""
}

.van-icon-send-gift-o:before {
    content: ""
}

.van-icon-share-o:before {
    content: ""
}

.van-icon-setting:before {
    content: ""
}

.van-icon-points:before {
    content: ""
}

.van-icon-photograph:before {
    content: ""
}

.van-icon-shop:before {
    content: ""
}

.van-icon-shop-o:before {
    content: ""
}

.van-icon-shop-collect-o:before {
    content: ""
}

.van-icon-shop-collect:before {
    content: ""
}

.van-icon-smile:before {
    content: ""
}

.van-icon-shopping-cart-o:before {
    content: ""
}

.van-icon-sign:before {
    content: ""
}

.van-icon-sort:before {
    content: ""
}

.van-icon-star-o:before {
    content: ""
}

.van-icon-smile-comment-o:before {
    content: ""
}

.van-icon-stop:before {
    content: ""
}

.van-icon-stop-circle-o:before {
    content: ""
}

.van-icon-smile-o:before {
    content: ""
}

.van-icon-star:before {
    content: ""
}

.van-icon-success:before {
    content: ""
}

.van-icon-stop-circle:before {
    content: ""
}

.van-icon-records:before {
    content: ""
}

.van-icon-shopping-cart:before {
    content: ""
}

.van-icon-tosend:before {
    content: ""
}

.van-icon-todo-list:before {
    content: ""
}

.van-icon-thumb-circle-o:before {
    content: ""
}

.van-icon-thumb-circle:before {
    content: ""
}

.van-icon-umbrella-circle:before {
    content: ""
}

.van-icon-underway:before {
    content: ""
}

.van-icon-upgrade:before {
    content: ""
}

.van-icon-todo-list-o:before {
    content: ""
}

.van-icon-tv-o:before {
    content: ""
}

.van-icon-underway-o:before {
    content: ""
}

.van-icon-user-o:before {
    content: ""
}

.van-icon-vip-card-o:before {
    content: ""
}

.van-icon-vip-card:before {
    content: ""
}

.van-icon-send-gift:before {
    content: ""
}

.van-icon-wap-home:before {
    content: ""
}

.van-icon-wap-nav:before {
    content: ""
}

.van-icon-volume-o:before {
    content: ""
}

.van-icon-video:before {
    content: ""
}

.van-icon-wap-home-o:before {
    content: ""
}

.van-icon-volume:before {
    content: ""
}

.van-icon-warning:before {
    content: ""
}

.van-icon-weapp-nav:before {
    content: ""
}

.van-icon-wechat-pay:before {
    content: ""
}

.van-icon-warning-o:before {
    content: ""
}

.van-icon-wechat:before {
    content: ""
}

.van-icon-setting-o:before {
    content: ""
}

.van-icon-youzan-shield:before {
    content: ""
}

.van-icon-warn-o:before {
    content: ""
}

.van-icon-smile-comment:before {
    content: ""
}

.van-icon-user-circle-o:before {
    content: ""
}

.van-icon-video-o:before {
    content: ""
}

.van-icon-add-square:before {
    content: ""
}

.van-icon-add:before {
    content: ""
}

.van-icon-arrow-down:before {
    content: ""
}

.van-icon-arrow-up:before {
    content: ""
}

.van-icon-arrow:before {
    content: ""
}

.van-icon-after-sale:before {
    content: ""
}

.van-icon-add-o:before {
    content: ""
}

.van-icon-alipay:before {
    content: ""
}

.van-icon-ascending:before {
    content: ""
}

.van-icon-apps-o:before {
    content: ""
}

.van-icon-aim:before {
    content: ""
}

.van-icon-award:before {
    content: ""
}

.van-icon-arrow-left:before {
    content: ""
}

.van-icon-award-o:before {
    content: ""
}

.van-icon-audio:before {
    content: ""
}

.van-icon-bag-o:before {
    content: ""
}

.van-icon-balance-list:before {
    content: ""
}

.van-icon-back-top:before {
    content: ""
}

.van-icon-bag:before {
    content: ""
}

.van-icon-balance-pay:before {
    content: ""
}

.van-icon-balance-o:before {
    content: ""
}

.van-icon-bar-chart-o:before {
    content: ""
}

.van-icon-bars:before {
    content: ""
}

.van-icon-balance-list-o:before {
    content: ""
}

.van-icon-birthday-cake-o:before {
    content: ""
}

.van-icon-bookmark:before {
    content: ""
}

.van-icon-bill:before {
    content: ""
}

.van-icon-bell:before {
    content: ""
}

.van-icon-browsing-history-o:before {
    content: ""
}

.van-icon-browsing-history:before {
    content: ""
}

.van-icon-bookmark-o:before {
    content: ""
}

.van-icon-bulb-o:before {
    content: ""
}

.van-icon-bullhorn-o:before {
    content: ""
}

.van-icon-bill-o:before {
    content: ""
}

.van-icon-calendar-o:before {
    content: ""
}

.van-icon-brush-o:before {
    content: ""
}

.van-icon-card:before {
    content: ""
}

.van-icon-cart-o:before {
    content: ""
}

.van-icon-cart-circle:before {
    content: ""
}

.van-icon-cart-circle-o:before {
    content: ""
}

.van-icon-cart:before {
    content: ""
}

.van-icon-cash-on-deliver:before {
    content: ""
}

.van-icon-cash-back-record:before {
    content: ""
}

.van-icon-cashier-o:before {
    content: ""
}

.van-icon-chart-trending-o:before {
    content: ""
}

.van-icon-certificate:before {
    content: ""
}

.van-icon-chat:before {
    content: ""
}

.van-icon-clear:before {
    content: ""
}

.van-icon-chat-o:before {
    content: ""
}

.van-icon-checked:before {
    content: ""
}

.van-icon-clock:before {
    content: ""
}

.van-icon-clock-o:before {
    content: ""
}

.van-icon-close:before {
    content: ""
}

.van-icon-closed-eye:before {
    content: ""
}

.van-icon-circle:before {
    content: ""
}

.van-icon-cluster-o:before {
    content: ""
}

.van-icon-column:before {
    content: ""
}

.van-icon-comment-circle-o:before {
    content: ""
}

.van-icon-cluster:before {
    content: ""
}

.van-icon-comment:before {
    content: ""
}

.van-icon-comment-o:before {
    content: ""
}

.van-icon-comment-circle:before {
    content: ""
}

.van-icon-completed:before {
    content: ""
}

.van-icon-credit-pay:before {
    content: ""
}

.van-icon-coupon:before {
    content: ""
}

.van-icon-debit-pay:before {
    content: ""
}

.van-icon-coupon-o:before {
    content: ""
}

.van-icon-contact:before {
    content: ""
}

.van-icon-descending:before {
    content: ""
}

.van-icon-desktop-o:before {
    content: ""
}

.van-icon-diamond-o:before {
    content: ""
}

.van-icon-description:before {
    content: ""
}

.van-icon-delete:before {
    content: ""
}

.van-icon-diamond:before {
    content: ""
}

.van-icon-delete-o:before {
    content: ""
}

.van-icon-cross:before {
    content: ""
}

.van-icon-edit:before {
    content: ""
}

.van-icon-ellipsis:before {
    content: ""
}

.van-icon-down:before {
    content: ""
}

.van-icon-discount:before {
    content: ""
}

.van-icon-ecard-pay:before {
    content: ""
}

.van-icon-envelop-o:before {
    content: ""
}

.van-icon-shield-o:before {
    content: ""
}

.van-icon-guide-o:before {
    content: ""
}

.van-icon-cash-o:before {
    content: ""
}

.van-icon-qq:before {
    content: ""
}

.van-icon-wechat-moments:before {
    content: ""
}

.van-icon-weibo:before {
    content: ""
}

.van-icon-link-o:before {
    content: ""
}

.van-icon-miniprogram-o:before {
    content: ""
}

.van-icon__image {
    display: block;
    width: 1em;
    height: 1em;
    object-fit: contain
}

:root {
    --van-checkbox-size: 20px;
    --van-checkbox-border-color: var(--van-gray-5);
    --van-checkbox-duration: var(--van-duration-fast);
    --van-checkbox-label-margin: var(--van-padding-xs);
    --van-checkbox-label-color: var(--van-text-color);
    --van-checkbox-checked-icon-color: var(--van-primary-color);
    --van-checkbox-disabled-icon-color: var(--van-gray-5);
    --van-checkbox-disabled-label-color: var(--van-text-color-3);
    --van-checkbox-disabled-background: var(--van-border-color)
}

.van-checkbox {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.van-checkbox--disabled {
    cursor: not-allowed
}

.van-checkbox--label-disabled {
    cursor: default
}

.van-checkbox--horizontal {
    margin-right: var(--van-padding-sm)
}

.van-checkbox__icon {
    -webkit-box-flex: 0;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
    height: 1em;
    font-size: var(--van-checkbox-size);
    line-height: 1em;
    cursor: pointer
}

.van-checkbox__icon .van-icon {
    display: block;
    box-sizing: border-box;
    width: 1.25em;
    height: 1.25em;
    color: transparent;
    font-size: .8em;
    line-height: 1.25;
    text-align: center;
    border: 1px solid var(--van-checkbox-border-color);
    -webkit-transition-duration: var(--van-checkbox-duration);
    transition-duration: var(--van-checkbox-duration);
    -webkit-transition-property: color,border-color,background-color;
    transition-property: color,border-color,background-color
}

.van-checkbox__icon--round .van-icon {
    border-radius: 100%
}

.van-checkbox__icon--indeterminate .van-icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: var(--van-white);
    border-color: var(--van-checkbox-checked-icon-color);
    background-color: var(--van-checkbox-checked-icon-color)
}

.van-checkbox__icon--checked .van-icon {
    color: var(--van-white);
    background-color: var(--van-checkbox-checked-icon-color);
    border-color: var(--van-checkbox-checked-icon-color)
}

.van-checkbox__icon--disabled {
    cursor: not-allowed
}

.van-checkbox__icon--disabled .van-icon {
    background-color: var(--van-checkbox-disabled-background);
    border-color: var(--van-checkbox-disabled-icon-color)
}

.van-checkbox__icon--disabled.van-checkbox__icon--checked .van-icon {
    color: var(--van-checkbox-disabled-icon-color)
}

.van-checkbox__label {
    margin-left: var(--van-checkbox-label-margin);
    color: var(--van-checkbox-label-color);
    line-height: var(--van-checkbox-size)
}

.van-checkbox__label--left {
    margin: 0 var(--van-checkbox-label-margin) 0 0
}

.van-checkbox__label--disabled {
    color: var(--van-checkbox-disabled-label-color)
}

:root {
    --van-radio-size: 20px;
    --van-radio-dot-size: 8px;
    --van-radio-border-color: var(--van-gray-5);
    --van-radio-duration: var(--van-duration-fast);
    --van-radio-label-margin: var(--van-padding-xs);
    --van-radio-label-color: var(--van-text-color);
    --van-radio-checked-icon-color: var(--van-primary-color);
    --van-radio-disabled-icon-color: var(--van-gray-5);
    --van-radio-disabled-label-color: var(--van-text-color-3);
    --van-radio-disabled-background: var(--van-border-color)
}

.van-radio {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.van-radio--disabled {
    cursor: not-allowed
}

.van-radio--label-disabled {
    cursor: default
}

.van-radio--horizontal {
    margin-right: var(--van-padding-sm)
}

.van-radio__icon {
    -webkit-box-flex: 0;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
    height: 1em;
    font-size: var(--van-radio-size);
    line-height: 1em;
    cursor: pointer
}

.van-radio__icon .van-icon {
    display: block;
    box-sizing: border-box;
    width: 1.25em;
    height: 1.25em;
    color: transparent;
    font-size: .8em;
    line-height: 1.25;
    text-align: center;
    border: 1px solid var(--van-radio-border-color);
    -webkit-transition-duration: var(--van-radio-duration);
    transition-duration: var(--van-radio-duration);
    -webkit-transition-property: color,border-color,background-color;
    transition-property: color,border-color,background-color
}

.van-radio__icon--round .van-icon {
    border-radius: 100%
}

.van-radio__icon--dot {
    position: relative;
    border-radius: 100%;
    box-sizing: border-box;
    width: var(--van-radio-size);
    height: var(--van-radio-size);
    border: 1px solid var(--van-radio-border-color);
    -webkit-transition-duration: var(--van-radio-duration);
    transition-duration: var(--van-radio-duration);
    -webkit-transition-property: border-color;
    transition-property: border-color
}

.van-radio__icon--dot__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    border-radius: 100%;
    height: calc(100% - var(--van-radio-dot-size));
    width: calc(100% - var(--van-radio-dot-size));
    -webkit-transition-duration: var(--van-radio-duration);
    transition-duration: var(--van-radio-duration);
    -webkit-transition-property: background-color;
    transition-property: background-color
}

.van-radio__icon--checked .van-icon {
    color: var(--van-white);
    background-color: var(--van-radio-checked-icon-color);
    border-color: var(--van-radio-checked-icon-color)
}

.van-radio__icon--checked.van-radio__icon--dot {
    border-color: var(--van-radio-checked-icon-color)
}

.van-radio__icon--checked.van-radio__icon--dot .van-radio__icon--dot__icon {
    background: var(--van-radio-checked-icon-color)
}

.van-radio__icon--disabled {
    cursor: not-allowed
}

.van-radio__icon--disabled .van-icon {
    background-color: var(--van-radio-disabled-background);
    border-color: var(--van-radio-disabled-icon-color)
}

.van-radio__icon--disabled.van-radio__icon--checked .van-icon {
    color: var(--van-radio-disabled-icon-color)
}

.van-radio__label {
    margin-left: var(--van-radio-label-margin);
    color: var(--van-radio-label-color);
    line-height: var(--van-radio-size)
}

.van-radio__label--left {
    margin: 0 var(--van-radio-label-margin) 0 0
}

.van-radio__label--disabled {
    color: var(--van-radio-disabled-label-color)
}

:root {
    --van-steps-background: var(--van-background-2)
}

.van-steps {
    overflow: hidden;
    background-color: var(--van-steps-background)
}

.van-steps--horizontal {
    padding: 10px 10px 0
}

.van-steps--horizontal .van-steps__items {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 0 0 10px;
    padding-bottom: 22px
}

.van-steps--vertical {
    padding: 0 0 0 var(--van-padding-xl)
}

:root {
    --van-step-text-color: var(--van-text-color-2);
    --van-step-active-color: var(--van-primary-color);
    --van-step-process-text-color: var(--van-text-color);
    --van-step-font-size: var(--van-font-size-md);
    --van-step-line-color: var(--van-border-color);
    --van-step-finish-line-color: var(--van-primary-color);
    --van-step-finish-text-color: var(--van-text-color);
    --van-step-icon-size: 12px;
    --van-step-circle-size: 5px;
    --van-step-circle-color: var(--van-gray-6);
    --van-step-horizontal-title-font-size: var(--van-font-size-sm)
}

.van-step {
    position: relative;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    color: var(--van-step-text-color);
    font-size: var(--van-step-font-size)
}

.van-step__circle {
    display: block;
    width: var(--van-step-circle-size);
    height: var(--van-step-circle-size);
    background-color: var(--van-step-circle-color);
    border-radius: 50%
}

.van-step__line {
    position: absolute;
    background-color: var(--van-step-line-color);
    -webkit-transition: background-color var(--van-duration-base);
    transition: background-color var(--van-duration-base)
}

.van-step--horizontal {
    float: left
}

.van-step--horizontal:first-child .van-step__title {
    margin-left: 0;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none
}

.van-step--horizontal:last-child:not(:first-child) {
    position: absolute;
    right: 1px;
    width: auto
}

.van-step--horizontal:last-child:not(:first-child) .van-step__title {
    margin-left: 0;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none
}

.van-step--horizontal:last-child:not(:first-child) .van-step__circle-container {
    right: -9px;
    left: auto
}

.van-step--horizontal .van-step__circle-container {
    position: absolute;
    top: 30px;
    left: calc(var(--van-padding-xs) * -1);
    z-index: 1;
    padding: 0 var(--van-padding-xs);
    background-color: var(--van-background-2);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.van-step--horizontal .van-step__title {
    display: inline-block;
    margin-left: 3px;
    font-size: var(--van-step-horizontal-title-font-size);
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%)
}

.van-step--horizontal .van-step__line {
    top: 30px;
    left: 0;
    width: 100%;
    height: 1px
}

.van-step--horizontal .van-step__icon {
    display: block;
    font-size: var(--van-step-icon-size)
}

.van-step--horizontal .van-step--process {
    color: var(--van-step-process-text-color)
}

.van-step--vertical {
    display: block;
    float: none;
    padding: 10px 10px 10px 0;
    line-height: var(--van-line-height-sm)
}

.van-step--vertical:not(:last-child):after {
    border-bottom-width: 1px
}

.van-step--vertical .van-step__circle-container {
    position: absolute;
    top: 19px;
    left: -15px;
    z-index: 1;
    font-size: var(--van-step-icon-size);
    line-height: 1;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
}

.van-step--vertical .van-step__line {
    top: 16px;
    left: -15px;
    width: 1px;
    height: 100%
}

.van-step:last-child .van-step__line {
    width: 0
}

.van-step--finish {
    color: var(--van-step-finish-text-color)
}

.van-step--finish .van-step__circle,.van-step--finish .van-step__line {
    background-color: var(--van-step-finish-line-color)
}

.van-step__icon,.van-step__title {
    -webkit-transition: color var(--van-duration-base);
    transition: color var(--van-duration-base)
}

.van-step__icon--active,.van-step__title--active,.van-step__icon--finish,.van-step__title--finish {
    color: var(--van-step-active-color)
}

:root {
    --van-sticky-z-index: 99
}

.van-sticky--fixed {
    position: fixed;
    z-index: var(--van-sticky-z-index)
}

:root {
    --van-swipe-indicator-size: 6px;
    --van-swipe-indicator-margin: var(--van-padding-sm);
    --van-swipe-indicator-active-opacity: 1;
    --van-swipe-indicator-inactive-opacity: .3;
    --van-swipe-indicator-active-background: var(--van-primary-color);
    --van-swipe-indicator-inactive-background: var(--van-border-color)
}

.van-swipe {
    position: relative;
    overflow: hidden;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    cursor: -webkit-grab;
    cursor: grab;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.van-swipe__track {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%
}

.van-swipe__track--vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
}

.van-swipe__indicators {
    position: absolute;
    bottom: var(--van-swipe-indicator-margin);
    left: 50%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%)
}

.van-swipe__indicators--vertical {
    top: 50%;
    bottom: auto;
    left: var(--van-swipe-indicator-margin);
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.van-swipe__indicators--vertical .van-swipe__indicator:not(:last-child) {
    margin-bottom: var(--van-swipe-indicator-size)
}

.van-swipe__indicator {
    width: var(--van-swipe-indicator-size);
    height: var(--van-swipe-indicator-size);
    background-color: var(--van-swipe-indicator-inactive-background);
    border-radius: 100%;
    opacity: var(--van-swipe-indicator-inactive-opacity);
    -webkit-transition: opacity var(--van-duration-fast),background-color var(--van-duration-fast);
    transition: opacity var(--van-duration-fast),background-color var(--van-duration-fast)
}

.van-swipe__indicator:not(:last-child) {
    margin-right: var(--van-swipe-indicator-size)
}

.van-swipe__indicator--active {
    background-color: var(--van-swipe-indicator-active-background);
    opacity: var(--van-swipe-indicator-active-opacity)
}

:root {
    --van-tab-text-color: var(--van-gray-7);
    --van-tab-active-text-color: var(--van-text-color);
    --van-tab-disabled-text-color: var(--van-text-color-3);
    --van-tab-font-size: var(--van-font-size-md);
    --van-tab-line-height: var(--van-line-height-md);
    --van-tabs-default-color: var(--van-primary-color);
    --van-tabs-line-height: 44px;
    --van-tabs-card-height: 30px;
    --van-tabs-nav-background: var(--van-background-2);
    --van-tabs-bottom-bar-width: 40px;
    --van-tabs-bottom-bar-height: 3px;
    --van-tabs-bottom-bar-color: var(--van-primary-color)
}

.van-tab {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0 var(--van-padding-base);
    color: var(--van-tab-text-color);
    font-size: var(--van-tab-font-size);
    line-height: var(--van-tab-line-height);
    cursor: pointer
}

.van-tab--active {
    color: var(--van-tab-active-text-color);
    font-weight: var(--van-font-bold)
}

.van-tab--disabled {
    color: var(--van-tab-disabled-text-color);
    cursor: not-allowed
}

.van-tab--grow {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    padding: 0 var(--van-padding-sm)
}

.van-tab--shrink {
    -webkit-box-flex: 0;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
    padding: 0 var(--van-padding-xs)
}

.van-tab--card {
    color: var(--van-tabs-default-color);
    border-right: var(--van-border-width) solid var(--van-tabs-default-color)
}

.van-tab--card:last-child {
    border-right: none
}

.van-tab--card.van-tab--active {
    color: var(--van-white);
    background-color: var(--van-tabs-default-color)
}

.van-tab--card--disabled {
    color: var(--van-tab-disabled-text-color)
}

.van-tab__text--ellipsis {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical
}

.van-tabs {
    position: relative
}

.van-tabs__wrap {
    overflow: hidden
}

.van-tabs__wrap--page-top {
    position: fixed
}

.van-tabs__wrap--content-bottom {
    top: auto;
    bottom: 0
}

.van-tabs__nav {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    background: var(--van-tabs-nav-background);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.van-tabs__nav--complete {
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch
}

.van-tabs__nav--complete::-webkit-scrollbar {
    display: none
}

.van-tabs__nav--line {
    box-sizing: content-box;
    height: 100%;
    padding-bottom: 15px
}

.van-tabs__nav--line.van-tabs__nav--shrink,.van-tabs__nav--line.van-tabs__nav--complete {
    padding-right: var(--van-padding-xs);
    padding-left: var(--van-padding-xs)
}

.van-tabs__nav--card {
    box-sizing: border-box;
    height: var(--van-tabs-card-height);
    margin: 0 var(--van-padding-md);
    border: var(--van-border-width) solid var(--van-tabs-default-color);
    border-radius: var(--van-border-radius-sm)
}

.van-tabs__nav--card.van-tabs__nav--shrink {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex
}

.van-tabs__line {
    position: absolute;
    bottom: 15px;
    left: 0;
    z-index: 1;
    width: var(--van-tabs-bottom-bar-width);
    height: var(--van-tabs-bottom-bar-height);
    background: var(--van-tabs-bottom-bar-color);
    border-radius: var(--van-tabs-bottom-bar-height)
}

.van-tabs__track {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    will-change: left
}

.van-tabs__content--animated {
    overflow: hidden
}

.van-tabs--line .van-tabs__wrap {
    height: var(--van-tabs-line-height)
}

.van-tabs--card>.van-tabs__wrap {
    height: var(--van-tabs-card-height)
}

.van-swipe-item {
    position: relative;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    height: 100%
}

.van-tab__panel,.van-tab__panel-wrapper {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    width: 100%
}

.van-tab__panel-wrapper--inactive {
    height: 0;
    overflow: visible
}

:root {
    --van-count-down-text-color: var(--van-text-color);
    --van-count-down-font-size: var(--van-font-size-md);
    --van-count-down-line-height: var(--van-line-height-md)
}

.van-count-down {
    color: var(--van-count-down-text-color);
    font-size: var(--van-count-down-font-size);
    line-height: var(--van-count-down-line-height)
}

.van-rolling-text {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: var(--van-rolling-text-font-size);
    color: var(--van-rolling-text-color)
}

.van-rolling-text-item:last-child {
    margin-right: 0
}

.van-rolling-text-item__box {
    overflow: hidden
}

.van-rolling-text-item__box--animate {
    -webkit-animation: van-up var(--van-duration) ease-in-out var(--van-delay);
    animation: van-up var(--van-duration) ease-in-out var(--van-delay);
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.van-rolling-text-item__item {
    text-align: center
}

.van-rolling-text-item--down .van-rolling-text-item__box {
    -webkit-transform: translateY(var(--van-translate));
    -ms-transform: translateY(var(--van-translate));
    transform: translateY(var(--van-translate))
}

.van-rolling-text-item--down .van-rolling-text-item__box--animate {
    -webkit-animation-name: van-down;
    animation-name: van-down
}

@-webkit-keyframes van-down {
    0% {
        -webkit-transform: translateY(var(--van-translate));
        transform: translateY(var(--van-translate))
    }

    to {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes van-down {
    0% {
        -webkit-transform: translateY(var(--van-translate));
        transform: translateY(var(--van-translate))
    }

    to {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@-webkit-keyframes van-up {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    to {
        -webkit-transform: translateY(var(--van-translate));
        transform: translateY(var(--van-translate))
    }
}

@keyframes van-up {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    to {
        -webkit-transform: translateY(var(--van-translate));
        transform: translateY(var(--van-translate))
    }
}

:root {
    --van-overlay-z-index: 1;
    --van-overlay-background: rgba(0, 0, 0, .7)
}

.van-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--van-overlay-z-index);
    width: 100%;
    height: 100%;
    background: var(--van-overlay-background)
}

:root {
    --van-popup-background: var(--van-background-2);
    --van-popup-transition: transform var(--van-duration-base);
    --van-popup-round-radius: 16px;
    --van-popup-close-icon-size: 22px;
    --van-popup-close-icon-color: var(--van-gray-5);
    --van-popup-close-icon-margin: 16px;
    --van-popup-close-icon-z-index: 1
}

.van-overflow-hidden {
    overflow: hidden!important
}

.van-popup {
    position: fixed;
    max-height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    background: var(--van-popup-background);
    -webkit-transition: var(--van-popup-transition);
    transition: var(--van-popup-transition);
    -webkit-overflow-scrolling: touch
}

.van-popup--center {
    top: 50%;
    left: 0;
    right: 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: calc(100vw - var(--van-padding-md) * 2);
    margin: 0 auto;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.van-popup--center.van-popup--round {
    border-radius: var(--van-popup-round-radius)
}

.van-popup--top {
    top: 0;
    left: 0;
    width: 100%
}

.van-popup--top.van-popup--round {
    border-radius: 0 0 var(--van-popup-round-radius) var(--van-popup-round-radius)
}

.van-popup--right {
    top: 50%;
    right: 0;
    -webkit-transform: translate3d(0,-50%,0);
    transform: translate3d(0,-50%,0)
}

.van-popup--right.van-popup--round {
    border-radius: var(--van-popup-round-radius) 0 0 var(--van-popup-round-radius)
}

.van-popup--bottom {
    bottom: 0;
    left: 0;
    width: 100%
}

.van-popup--bottom.van-popup--round {
    border-radius: var(--van-popup-round-radius) var(--van-popup-round-radius) 0 0
}

.van-popup--left {
    top: 50%;
    left: 0;
    -webkit-transform: translate3d(0,-50%,0);
    transform: translate3d(0,-50%,0)
}

.van-popup--left.van-popup--round {
    border-radius: 0 var(--van-popup-round-radius) var(--van-popup-round-radius) 0
}

.van-popup-slide-top-enter-active,.van-popup-slide-left-enter-active,.van-popup-slide-right-enter-active,.van-popup-slide-bottom-enter-active {
    -webkit-transition-timing-function: var(--van-ease-out);
    transition-timing-function: var(--van-ease-out)
}

.van-popup-slide-top-leave-active,.van-popup-slide-left-leave-active,.van-popup-slide-right-leave-active,.van-popup-slide-bottom-leave-active {
    -webkit-transition-timing-function: var(--van-ease-in);
    transition-timing-function: var(--van-ease-in)
}

.van-popup-slide-top-enter-from,.van-popup-slide-top-leave-active {
    -webkit-transform: translate3d(0,-100%,0);
    transform: translate3d(0,-100%,0)
}

.van-popup-slide-right-enter-from,.van-popup-slide-right-leave-active {
    -webkit-transform: translate3d(100%,-50%,0);
    transform: translate3d(100%,-50%,0)
}

.van-popup-slide-bottom-enter-from,.van-popup-slide-bottom-leave-active {
    -webkit-transform: translate3d(0,100%,0);
    transform: translate3d(0,100%,0)
}

.van-popup-slide-left-enter-from,.van-popup-slide-left-leave-active {
    -webkit-transform: translate3d(-100%,-50%,0);
    transform: translate3d(-100%,-50%,0)
}

.van-popup__close-icon {
    position: absolute;
    z-index: var(--van-popup-close-icon-z-index);
    color: var(--van-popup-close-icon-color);
    font-size: var(--van-popup-close-icon-size)
}

.van-popup__close-icon--top-left {
    top: var(--van-popup-close-icon-margin);
    left: var(--van-popup-close-icon-margin)
}

.van-popup__close-icon--top-right {
    top: var(--van-popup-close-icon-margin);
    right: var(--van-popup-close-icon-margin)
}

.van-popup__close-icon--bottom-left {
    bottom: var(--van-popup-close-icon-margin);
    left: var(--van-popup-close-icon-margin)
}

.van-popup__close-icon--bottom-right {
    right: var(--van-popup-close-icon-margin);
    bottom: var(--van-popup-close-icon-margin)
}

:root {
    --van-cell-font-size: var(--van-font-size-md);
    --van-cell-line-height: 24px;
    --van-cell-vertical-padding: 10px;
    --van-cell-horizontal-padding: var(--van-padding-md);
    --van-cell-text-color: var(--van-text-color);
    --van-cell-background: var(--van-background-2);
    --van-cell-border-color: var(--van-border-color);
    --van-cell-active-color: var(--van-active-color);
    --van-cell-required-color: var(--van-danger-color);
    --van-cell-label-color: var(--van-text-color-2);
    --van-cell-label-font-size: var(--van-font-size-sm);
    --van-cell-label-line-height: var(--van-line-height-sm);
    --van-cell-label-margin-top: var(--van-padding-base);
    --van-cell-value-color: var(--van-text-color-2);
    --van-cell-value-font-size: inherit;
    --van-cell-icon-size: 16px;
    --van-cell-right-icon-color: var(--van-gray-6);
    --van-cell-large-vertical-padding: var(--van-padding-sm);
    --van-cell-large-title-font-size: var(--van-font-size-lg);
    --van-cell-large-label-font-size: var(--van-font-size-md);
    --van-cell-large-value-font-size: inherit
}

.van-cell {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    padding: var(--van-cell-vertical-padding) var(--van-cell-horizontal-padding);
    overflow: hidden;
    color: var(--van-cell-text-color);
    font-size: var(--van-cell-font-size);
    line-height: var(--van-cell-line-height);
    background: var(--van-cell-background)
}

.van-cell:after {
    position: absolute;
    box-sizing: border-box;
    content: " ";
    pointer-events: none;
    right: var(--van-padding-md);
    bottom: 0;
    left: var(--van-padding-md);
    border-bottom: 1px solid var(--van-cell-border-color);
    -webkit-transform: scaleY(.5);
    -ms-transform: scaleY(.5);
    transform: scaleY(.5)
}

.van-cell:last-child:after,.van-cell--borderless:after {
    display: none
}

.van-cell__label {
    margin-top: var(--van-cell-label-margin-top);
    color: var(--van-cell-label-color);
    font-size: var(--van-cell-label-font-size);
    line-height: var(--van-cell-label-line-height)
}

.van-cell__title,.van-cell__value {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.van-cell__value {
    position: relative;
    overflow: hidden;
    color: var(--van-cell-value-color);
    font-size: var(--van-cell-value-font-size);
    text-align: right;
    vertical-align: middle;
    word-wrap: break-word
}

.van-cell__left-icon,.van-cell__right-icon {
    height: var(--van-cell-line-height);
    font-size: var(--van-cell-icon-size);
    line-height: var(--van-cell-line-height)
}

.van-cell__left-icon {
    margin-right: var(--van-padding-base)
}

.van-cell__right-icon {
    margin-left: var(--van-padding-base);
    color: var(--van-cell-right-icon-color)
}

.van-cell--clickable {
    cursor: pointer
}

.van-cell--clickable:active {
    background-color: var(--van-cell-active-color)
}

.van-cell--required {
    overflow: visible
}

.van-cell--required:before {
    position: absolute;
    left: var(--van-padding-xs);
    color: var(--van-cell-required-color);
    font-size: var(--van-cell-font-size);
    content: "*"
}

.van-cell--center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.van-cell--large {
    padding-top: var(--van-cell-large-vertical-padding);
    padding-bottom: var(--van-cell-large-vertical-padding)
}

.van-cell--large .van-cell__title {
    font-size: var(--van-cell-large-title-font-size)
}

.van-cell--large .van-cell__label {
    font-size: var(--van-cell-large-label-font-size)
}

.van-cell--large .van-cell__value {
    font-size: var(--van-cell-large-value-font-size)
}

.van-swipe-cell {
    position: relative;
    overflow: hidden;
    cursor: -webkit-grab;
    cursor: grab
}

.van-swipe-cell__wrapper {
    -webkit-transition-timing-function: cubic-bezier(.18,.89,.32,1);
    transition-timing-function: cubic-bezier(.18,.89,.32,1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform,-webkit-transform
}

.van-swipe-cell__left,.van-swipe-cell__right {
    position: absolute;
    top: 0;
    height: 100%
}

.van-swipe-cell__left {
    left: 0;
    -webkit-transform: translate3d(-100%,0,0);
    transform: translate3d(-100%,0,0)
}

.van-swipe-cell__right {
    right: 0;
    -webkit-transform: translate3d(100%,0,0);
    transform: translate3d(100%,0,0)
}

:root {
    --van-loading-text-color: var(--van-text-color-2);
    --van-loading-text-font-size: var(--van-font-size-md);
    --van-loading-spinner-color: var(--van-gray-5);
    --van-loading-spinner-size: 30px;
    --van-loading-spinner-duration: .8s
}

.van-loading {
    position: relative;
    color: var(--van-loading-spinner-color);
    font-size: 0;
    vertical-align: middle
}

.van-loading__spinner {
    position: relative;
    display: inline-block;
    width: var(--van-loading-spinner-size);
    max-width: 100%;
    height: var(--van-loading-spinner-size);
    max-height: 100%;
    vertical-align: middle;
    -webkit-animation: van-rotate var(--van-loading-spinner-duration) linear infinite;
    animation: van-rotate var(--van-loading-spinner-duration) linear infinite
}

.van-loading__spinner--spinner {
    -webkit-animation-timing-function: steps(12);
    animation-timing-function: steps(12)
}

.van-loading__spinner--circular {
    -webkit-animation-duration: 2s;
    animation-duration: 2s
}

.van-loading__line {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.van-loading__line:before {
    display: block;
    width: 2px;
    height: 25%;
    margin: 0 auto;
    background-color: currentColor;
    border-radius: 40%;
    content: " "
}

.van-loading__circular {
    display: block;
    width: 100%;
    height: 100%
}

.van-loading__circular circle {
    -webkit-animation: van-circular 1.5s ease-in-out infinite;
    animation: van-circular 1.5s ease-in-out infinite;
    stroke: currentColor;
    stroke-width: 3;
    stroke-linecap: round
}

.van-loading__text {
    display: inline-block;
    margin-left: var(--van-padding-xs);
    color: var(--van-loading-text-color);
    font-size: var(--van-loading-text-font-size);
    vertical-align: middle
}

.van-loading--vertical {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.van-loading--vertical .van-loading__text {
    margin: var(--van-padding-xs) 0 0
}

@-webkit-keyframes van-circular {
    0% {
        stroke-dasharray: 1,200;
        stroke-dashoffset: 0
    }

    50% {
        stroke-dasharray: 90,150;
        stroke-dashoffset: -40
    }

    to {
        stroke-dasharray: 90,150;
        stroke-dashoffset: -120
    }
}

@keyframes van-circular {
    0% {
        stroke-dasharray: 1,200;
        stroke-dashoffset: 0
    }

    50% {
        stroke-dasharray: 90,150;
        stroke-dashoffset: -40
    }

    to {
        stroke-dasharray: 90,150;
        stroke-dashoffset: -120
    }
}

.van-loading__line--1 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
    opacity: 1
}

.van-loading__line--2 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
    opacity: .9375
}

.van-loading__line--3 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: .875
}

.van-loading__line--4 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
    opacity: .8125
}

.van-loading__line--5 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
    opacity: .75
}

.van-loading__line--6 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    opacity: .6875
}

.van-loading__line--7 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
    opacity: .625
}

.van-loading__line--8 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
    opacity: .5625
}

.van-loading__line--9 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
    opacity: .5
}

.van-loading__line--10 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
    opacity: .4375
}

.van-loading__line--11 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
    opacity: .375
}

.van-loading__line--12 {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
    opacity: .3125
}

:root {
    --van-button-mini-height: 24px;
    --van-button-mini-padding: 0 var(--van-padding-base);
    --van-button-mini-font-size: var(--van-font-size-xs);
    --van-button-small-height: 32px;
    --van-button-small-padding: 0 var(--van-padding-xs);
    --van-button-small-font-size: var(--van-font-size-sm);
    --van-button-normal-padding: 0 15px;
    --van-button-normal-font-size: var(--van-font-size-md);
    --van-button-large-height: 50px;
    --van-button-default-height: 44px;
    --van-button-default-line-height: 1.2;
    --van-button-default-font-size: var(--van-font-size-lg);
    --van-button-default-color: var(--van-text-color);
    --van-button-default-background: var(--van-background-2);
    --van-button-default-border-color: var(--van-gray-4);
    --van-button-primary-color: var(--van-white);
    --van-button-primary-background: var(--van-primary-color);
    --van-button-primary-border-color: var(--van-primary-color);
    --van-button-success-color: var(--van-white);
    --van-button-success-background: var(--van-success-color);
    --van-button-success-border-color: var(--van-success-color);
    --van-button-danger-color: var(--van-white);
    --van-button-danger-background: var(--van-danger-color);
    --van-button-danger-border-color: var(--van-danger-color);
    --van-button-warning-color: var(--van-white);
    --van-button-warning-background: var(--van-warning-color);
    --van-button-warning-border-color: var(--van-warning-color);
    --van-button-border-width: var(--van-border-width);
    --van-button-radius: var(--van-radius-md);
    --van-button-round-radius: var(--van-radius-max);
    --van-button-plain-background: var(--van-white);
    --van-button-disabled-opacity: var(--van-disabled-opacity);
    --van-button-icon-size: 1.2em;
    --van-button-loading-icon-size: 20px
}

.van-theme-dark {
    --van-button-plain-background: transparent
}

.van-button {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    height: var(--van-button-default-height);
    margin: 0;
    padding: 0;
    font-size: var(--van-button-default-font-size);
    line-height: var(--van-button-default-line-height);
    text-align: center;
    border-radius: var(--van-button-radius);
    cursor: pointer;
    -webkit-transition: opacity var(--van-duration-fast);
    transition: opacity var(--van-duration-fast);
    -webkit-appearance: none;
    -webkit-font-smoothing: auto
}

.van-button:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background: var(--van-black);
    border: inherit;
    border-color: var(--van-black);
    border-radius: inherit;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    opacity: 0;
    content: " "
}

.van-button:active:before {
    opacity: .1
}

.van-button--loading:before,.van-button--disabled:before {
    display: none
}

.van-button--default {
    color: var(--van-button-default-color);
    background: var(--van-button-default-background);
    border: var(--van-button-border-width) solid var(--van-button-default-border-color)
}

.van-button--primary {
    color: var(--van-button-primary-color);
    background: var(--van-button-primary-background);
    border: var(--van-button-border-width) solid var(--van-button-primary-border-color)
}

.van-button--success {
    color: var(--van-button-success-color);
    background: var(--van-button-success-background);
    border: var(--van-button-border-width) solid var(--van-button-success-border-color)
}

.van-button--danger {
    color: var(--van-button-danger-color);
    background: var(--van-button-danger-background);
    border: var(--van-button-border-width) solid var(--van-button-danger-border-color)
}

.van-button--warning {
    color: var(--van-button-warning-color);
    background: var(--van-button-warning-background);
    border: var(--van-button-border-width) solid var(--van-button-warning-border-color)
}

.van-button--plain {
    background: var(--van-button-plain-background)
}

.van-button--plain.van-button--primary {
    color: var(--van-button-primary-background)
}

.van-button--plain.van-button--success {
    color: var(--van-button-success-background)
}

.van-button--plain.van-button--danger {
    color: var(--van-button-danger-background)
}

.van-button--plain.van-button--warning {
    color: var(--van-button-warning-background)
}

.van-button--large {
    width: 100%;
    height: var(--van-button-large-height)
}

.van-button--normal {
    padding: var(--van-button-normal-padding);
    font-size: var(--van-button-normal-font-size)
}

.van-button--small {
    height: var(--van-button-small-height);
    padding: var(--van-button-small-padding);
    font-size: var(--van-button-small-font-size)
}

.van-button__loading {
    color: inherit;
    font-size: inherit
}

.van-button__loading .van-loading__spinner {
    color: currentColor;
    width: var(--van-button-loading-icon-size);
    height: var(--van-button-loading-icon-size)
}

.van-button--mini {
    height: var(--van-button-mini-height);
    padding: var(--van-button-mini-padding);
    font-size: var(--van-button-mini-font-size)
}

.van-button--mini+.van-button--mini {
    margin-left: var(--van-padding-base)
}

.van-button--block {
    display: block;
    width: 100%
}

.van-button--disabled {
    cursor: not-allowed;
    opacity: var(--van-button-disabled-opacity)
}

.van-button--loading {
    cursor: default
}

.van-button--round {
    border-radius: var(--van-button-round-radius)
}

.van-button--square {
    border-radius: 0
}

.van-button__content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%
}

.van-button__content:before {
    content: " "
}

.van-button__icon {
    font-size: var(--van-button-icon-size);
    line-height: inherit
}

.van-button__icon+.van-button__text,.van-button__loading+.van-button__text,.van-button__text+.van-button__icon,.van-button__text+.van-button__loading {
    margin-left: var(--van-padding-base)
}

.van-button--hairline {
    border-width: 0
}

.van-button--hairline:after {
    border-color: inherit;
    border-radius: calc(var(--van-button-radius) * 2)
}

.van-button--hairline.van-button--round:after {
    border-radius: var(--van-button-round-radius)
}

.van-button--hairline.van-button--square:after {
    border-radius: 0
}

:root {
    --van-field-label-width: 6.2em;
    --van-field-label-color: var(--van-text-color);
    --van-field-label-margin-right: var(--van-padding-sm);
    --van-field-input-text-color: var(--van-text-color);
    --van-field-input-error-text-color: var(--van-danger-color);
    --van-field-input-disabled-text-color: var(--van-text-color-3);
    --van-field-placeholder-text-color: var(--van-text-color-3);
    --van-field-icon-size: 18px;
    --van-field-clear-icon-size: 18px;
    --van-field-clear-icon-color: var(--van-gray-5);
    --van-field-right-icon-color: var(--van-gray-6);
    --van-field-error-message-color: var(--van-danger-color);
    --van-field-error-message-font-size: 12px;
    --van-field-text-area-min-height: 60px;
    --van-field-word-limit-color: var(--van-gray-7);
    --van-field-word-limit-font-size: var(--van-font-size-sm);
    --van-field-word-limit-line-height: 16px;
    --van-field-disabled-text-color: var(--van-text-color-3);
    --van-field-required-mark-color: var(--van-red)
}

.van-field {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.van-field__label {
    -webkit-box-flex: 0;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
    box-sizing: border-box;
    width: var(--van-field-label-width);
    margin-right: var(--van-field-label-margin-right);
    color: var(--van-field-label-color);
    text-align: left;
    word-wrap: break-word
}

.van-field__label--center {
    text-align: center
}

.van-field__label--right {
    text-align: right
}

.van-field__label--top {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    text-align: left;
    margin-bottom: var(--van-padding-base);
    word-break: break-word
}

.van-field__label--required:before {
    margin-right: 2px;
    color: var(--van-field-required-mark-color);
    content: "*"
}

.van-field--disabled .van-field__label {
    color: var(--van-field-disabled-text-color)
}

.van-field__value {
    overflow: visible
}

.van-field__body {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.van-field__control {
    display: block;
    box-sizing: border-box;
    width: 100%;
    min-width: 0;
    margin: 0;
    padding: 0;
    color: var(--van-field-input-text-color);
    line-height: inherit;
    text-align: left;
    background-color: transparent;
    border: 0;
    resize: none;
    -webkit-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
    user-select: auto
}

.van-field__control::-webkit-input-placeholder {
    color: var(--van-field-placeholder-text-color)
}

.van-field__control::-moz-placeholder {
    color: var(--van-field-placeholder-text-color)
}

.van-field__control:-ms-input-placeholder {
    color: var(--van-field-placeholder-text-color)
}

.van-field__control::placeholder {
    color: var(--van-field-placeholder-text-color)
}

.van-field__control:-moz-read-only {
    cursor: default
}

.van-field__control:read-only {
    cursor: default
}

.van-field__control:disabled {
    color: var(--van-field-input-disabled-text-color);
    cursor: not-allowed;
    opacity: 1;
    -webkit-text-fill-color: var(--van-field-input-disabled-text-color)
}

.van-field__control--center {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center
}

.van-field__control--right {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: right
}

.van-field__control--custom {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: var(--van-cell-line-height)
}

.van-field__control--error::-webkit-input-placeholder {
    color: var(--van-field-input-error-text-color);
    -webkit-text-fill-color: currentColor
}

.van-field__control--error::-moz-placeholder {
    color: var(--van-field-input-error-text-color);
    -webkit-text-fill-color: currentColor
}

.van-field__control--error:-ms-input-placeholder {
    color: var(--van-field-input-error-text-color);
    -webkit-text-fill-color: currentColor
}

.van-field__control--error,.van-field__control--error::placeholder {
    color: var(--van-field-input-error-text-color);
    -webkit-text-fill-color: currentColor
}

.van-field__control--min-height {
    min-height: var(--van-field-text-area-min-height)
}

.van-field__control[type=date],.van-field__control[type=time],.van-field__control[type=datetime-local] {
    min-height: var(--van-cell-line-height)
}

.van-field__control[type=search] {
    -webkit-appearance: none
}

.van-field__clear,.van-field__icon,.van-field__button,.van-field__right-icon {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0
}

.van-field__clear,.van-field__right-icon {
    margin-right: calc(var(--van-padding-xs) * -1);
    padding: 0 var(--van-padding-xs);
    line-height: inherit
}

.van-field__clear {
    color: var(--van-field-clear-icon-color);
    font-size: var(--van-field-clear-icon-size);
    cursor: pointer
}

.van-field__left-icon .van-icon,.van-field__right-icon .van-icon {
    display: block;
    font-size: var(--van-field-icon-size);
    line-height: inherit
}

.van-field__left-icon {
    margin-right: var(--van-padding-base)
}

.van-field__right-icon {
    color: var(--van-field-right-icon-color)
}

.van-field__button {
    padding-left: var(--van-padding-xs)
}

.van-field__error-message {
    color: var(--van-field-error-message-color);
    font-size: var(--van-field-error-message-font-size);
    text-align: left
}

.van-field__error-message--center {
    text-align: center
}

.van-field__error-message--right {
    text-align: right
}

.van-field__word-limit {
    margin-top: var(--van-padding-base);
    color: var(--van-field-word-limit-color);
    font-size: var(--van-field-word-limit-font-size);
    line-height: var(--van-field-word-limit-line-height);
    text-align: right
}

:root {
    --van-floating-bubble-size: 48px;
    --van-floating-bubble-initial-gap: 24px;
    --van-floating-bubble-icon-size: 28px;
    --van-floating-bubble-background: var(--van-primary-color);
    --van-floating-bubble-color: var(--van-background-2);
    --van-floating-bubble-z-index: 999;
    --van-floating-bubble-border-radius: var(--van-radius-max)
}

.van-floating-bubble {
    position: fixed;
    left: 0;
    top: 0;
    right: var(--van-floating-bubble-initial-gap);
    bottom: var(--van-floating-bubble-initial-gap);
    width: var(--van-floating-bubble-size);
    height: var(--van-floating-bubble-size);
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: none;
    touch-action: none;
    background: var(--van-floating-bubble-background);
    color: var(--van-floating-bubble-color);
    border-radius: var(--van-floating-bubble-border-radius);
    z-index: var(--van-floating-bubble-z-index);
    -webkit-transition: -webkit-transform var(--van-duration-base);
    transition: -webkit-transform var(--van-duration-base);
    transition: transform var(--van-duration-base);
    transition: transform var(--van-duration-base),-webkit-transform var(--van-duration-base)
}

.van-floating-bubble:active {
    opacity: .8
}

.van-floating-bubble__icon {
    font-size: var(--van-floating-bubble-icon-size)
}

:root {
    --van-tabbar-height: 50px;
    --van-tabbar-z-index: 1;
    --van-tabbar-background: var(--van-background-2)
}

.van-tabbar {
    z-index: var(--van-tabbar-z-index);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    box-sizing: content-box;
    width: 100%;
    height: var(--van-tabbar-height);
    background: var(--van-tabbar-background)
}

.van-tabbar--fixed {
    position: fixed;
    bottom: 0;
    left: 0
}

:root {
    --van-tabbar-item-font-size: var(--van-font-size-sm);
    --van-tabbar-item-text-color: var(--van-text-color);
    --van-tabbar-item-active-color: var(--van-primary-color);
    --van-tabbar-item-active-background: var(--van-background-2);
    --van-tabbar-item-line-height: 1;
    --van-tabbar-item-icon-size: 22px;
    --van-tabbar-item-icon-margin-bottom: var(--van-padding-base)
}

.van-tabbar-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: var(--van-tabbar-item-text-color);
    font-size: var(--van-tabbar-item-font-size);
    line-height: var(--van-tabbar-item-line-height);
    cursor: pointer
}

.van-tabbar-item__icon {
    margin-bottom: var(--van-tabbar-item-icon-margin-bottom);
    font-size: var(--van-tabbar-item-icon-size)
}

.van-tabbar-item__icon .van-icon {
    display: block
}

.van-tabbar-item__icon .van-badge {
    margin-top: var(--van-padding-base)
}

.van-tabbar-item__icon img {
    display: block;
    height: 20px
}

.van-tabbar-item--active {
    color: var(--van-tabbar-item-active-color);
    background-color: var(--van-tabbar-item-active-background)
}

:root {
    --van-action-sheet-max-height: 80%;
    --van-action-sheet-header-height: 48px;
    --van-action-sheet-header-font-size: var(--van-font-size-lg);
    --van-action-sheet-description-color: var(--van-text-color-2);
    --van-action-sheet-description-font-size: var(--van-font-size-md);
    --van-action-sheet-description-line-height: var(--van-line-height-md);
    --van-action-sheet-item-background: var(--van-background-2);
    --van-action-sheet-item-font-size: var(--van-font-size-lg);
    --van-action-sheet-item-line-height: var(--van-line-height-lg);
    --van-action-sheet-item-text-color: var(--van-text-color);
    --van-action-sheet-item-disabled-text-color: var(--van-text-color-3);
    --van-action-sheet-subname-color: var(--van-text-color-2);
    --van-action-sheet-subname-font-size: var(--van-font-size-sm);
    --van-action-sheet-subname-line-height: var(--van-line-height-sm);
    --van-action-sheet-close-icon-size: 22px;
    --van-action-sheet-close-icon-color: var(--van-gray-5);
    --van-action-sheet-close-icon-padding: 0 var(--van-padding-md);
    --van-action-sheet-cancel-text-color: var(--van-gray-7);
    --van-action-sheet-cancel-padding-top: var(--van-padding-xs);
    --van-action-sheet-cancel-padding-color: var(--van-background);
    --van-action-sheet-loading-icon-size: 22px
}

.van-action-sheet {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    max-height: var(--van-action-sheet-max-height);
    overflow: hidden;
    color: var(--van-action-sheet-item-text-color)
}

.van-action-sheet__content {
    -webkit-box-flex: 1;
    -webkit-flex: 1 auto;
    -ms-flex: 1 auto;
    flex: 1 auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch
}

.van-action-sheet__item,.van-action-sheet__cancel {
    display: block;
    width: 100%;
    padding: 14px var(--van-padding-md);
    font-size: var(--van-action-sheet-item-font-size);
    background: var(--van-action-sheet-item-background);
    border: none;
    cursor: pointer
}

.van-action-sheet__item:active,.van-action-sheet__cancel:active {
    background-color: var(--van-active-color)
}

.van-action-sheet__item {
    line-height: var(--van-action-sheet-item-line-height)
}

.van-action-sheet__item--loading,.van-action-sheet__item--disabled {
    color: var(--van-action-sheet-item-disabled-text-color)
}

.van-action-sheet__item--loading:active,.van-action-sheet__item--disabled:active {
    background-color: var(--van-action-sheet-item-background)
}

.van-action-sheet__item--disabled {
    cursor: not-allowed
}

.van-action-sheet__item--loading {
    cursor: default
}

.van-action-sheet__cancel {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    color: var(--van-action-sheet-cancel-text-color)
}

.van-action-sheet__subname {
    margin-top: var(--van-padding-xs);
    color: var(--van-action-sheet-subname-color);
    font-size: var(--van-action-sheet-subname-font-size);
    line-height: var(--van-action-sheet-subname-line-height)
}

.van-action-sheet__gap {
    display: block;
    height: var(--van-action-sheet-cancel-padding-top);
    background: var(--van-action-sheet-cancel-padding-color)
}

.van-action-sheet__header {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    font-weight: var(--van-font-bold);
    font-size: var(--van-action-sheet-header-font-size);
    line-height: var(--van-action-sheet-header-height);
    text-align: center
}

.van-action-sheet__description {
    position: relative;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding: 20px var(--van-padding-md);
    color: var(--van-action-sheet-description-color);
    font-size: var(--van-action-sheet-description-font-size);
    line-height: var(--van-action-sheet-description-line-height);
    text-align: center
}

.van-action-sheet__description:after {
    position: absolute;
    box-sizing: border-box;
    content: " ";
    pointer-events: none;
    right: var(--van-padding-md);
    bottom: 0;
    left: var(--van-padding-md);
    border-bottom: 1px solid var(--van-border-color);
    -webkit-transform: scaleY(.5);
    -ms-transform: scaleY(.5);
    transform: scaleY(.5)
}

.van-action-sheet__loading-icon .van-loading__spinner {
    width: var(--van-action-sheet-loading-icon-size);
    height: var(--van-action-sheet-loading-icon-size)
}

.van-action-sheet__close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    padding: var(--van-action-sheet-close-icon-padding);
    color: var(--van-action-sheet-close-icon-color);
    font-size: var(--van-action-sheet-close-icon-size);
    line-height: inherit
}

:root {
    --van-sidebar-width: 80px
}

.van-sidebar {
    width: var(--van-sidebar-width);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch
}

:root {
    --van-sidebar-font-size: var(--van-font-size-md);
    --van-sidebar-line-height: var(--van-line-height-md);
    --van-sidebar-text-color: var(--van-text-color);
    --van-sidebar-disabled-text-color: var(--van-text-color-3);
    --van-sidebar-padding: 20px var(--van-padding-sm);
    --van-sidebar-active-color: var(--van-active-color);
    --van-sidebar-background: var(--van-background);
    --van-sidebar-selected-font-weight: var(--van-font-bold);
    --van-sidebar-selected-text-color: var(--van-text-color);
    --van-sidebar-selected-border-width: 4px;
    --van-sidebar-selected-border-height: 16px;
    --van-sidebar-selected-border-color: var(--van-primary-color);
    --van-sidebar-selected-background: var(--van-background-2)
}

.van-sidebar-item {
    position: relative;
    display: block;
    box-sizing: border-box;
    padding: var(--van-sidebar-padding);
    overflow: hidden;
    color: var(--van-sidebar-text-color);
    font-size: var(--van-sidebar-font-size);
    line-height: var(--van-sidebar-line-height);
    background: var(--van-sidebar-background);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.van-sidebar-item:active {
    background-color: var(--van-sidebar-active-color)
}

.van-sidebar-item:not(:last-child):after {
    border-bottom-width: 1px
}

.van-sidebar-item__text {
    word-break: break-all
}

.van-sidebar-item--select {
    color: var(--van-sidebar-selected-text-color);
    font-weight: var(--van-sidebar-selected-font-weight)
}

.van-sidebar-item--select,.van-sidebar-item--select:active {
    background-color: var(--van-sidebar-selected-background)
}

.van-sidebar-item--select:before {
    position: absolute;
    top: 50%;
    left: 0;
    width: var(--van-sidebar-selected-border-width);
    height: var(--van-sidebar-selected-border-height);
    background-color: var(--van-sidebar-selected-border-color);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    content: ""
}

.van-sidebar-item--disabled {
    color: var(--van-sidebar-disabled-text-color);
    cursor: not-allowed
}

.van-sidebar-item--disabled:active {
    background-color: var(--van-sidebar-background)
}

:root {
    --van-slider-active-background: var(--van-primary-color);
    --van-slider-inactive-background: var(--van-gray-3);
    --van-slider-disabled-opacity: var(--van-disabled-opacity);
    --van-slider-bar-height: 2px;
    --van-slider-button-width: 24px;
    --van-slider-button-height: 24px;
    --van-slider-button-radius: 50%;
    --van-slider-button-background: var(--van-white);
    --van-slider-button-shadow: 0 1px 2px rgba(0, 0, 0, .5)
}

.van-theme-dark {
    --van-slider-inactive-background: var(--van-background-3)
}

.van-slider {
    position: relative;
    width: 100%;
    height: var(--van-slider-bar-height);
    background: var(--van-slider-inactive-background);
    border-radius: var(--van-radius-max);
    cursor: pointer
}

.van-slider:before {
    position: absolute;
    top: calc(var(--van-padding-xs) * -1);
    right: 0;
    bottom: calc(var(--van-padding-xs) * -1);
    left: 0;
    content: ""
}

.van-slider__bar {
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--van-slider-active-background);
    border-radius: inherit;
    -webkit-transition: all var(--van-duration-fast);
    transition: all var(--van-duration-fast)
}

.van-slider__button {
    width: var(--van-slider-button-width);
    height: var(--van-slider-button-height);
    background: var(--van-slider-button-background);
    border-radius: var(--van-slider-button-radius);
    box-shadow: var(--van-slider-button-shadow)
}

.van-slider__button-wrapper {
    position: absolute;
    cursor: -webkit-grab;
    cursor: grab;
    top: 50%
}

.van-slider__button-wrapper--right {
    right: 0;
    -webkit-transform: translate3d(50%,-50%,0);
    transform: translate3d(50%,-50%,0)
}

.van-slider__button-wrapper--left {
    left: 0;
    -webkit-transform: translate3d(-50%,-50%,0);
    transform: translate3d(-50%,-50%,0)
}

.van-slider--disabled {
    cursor: not-allowed;
    opacity: var(--van-slider-disabled-opacity)
}

.van-slider--disabled .van-slider__button-wrapper {
    cursor: not-allowed
}

.van-slider--vertical {
    display: inline-block;
    width: var(--van-slider-bar-height);
    height: 100%
}

.van-slider--vertical .van-slider__button-wrapper--right {
    top: auto;
    right: 50%;
    bottom: 0;
    -webkit-transform: translate3d(50%,50%,0);
    transform: translate3d(50%,50%,0)
}

.van-slider--vertical .van-slider__button-wrapper--left {
    top: 0;
    right: 50%;
    left: auto;
    -webkit-transform: translate3d(50%,-50%,0);
    transform: translate3d(50%,-50%,0)
}

.van-slider--vertical:before {
    top: 0;
    right: calc(var(--van-padding-xs) * -1);
    bottom: 0;
    left: calc(var(--van-padding-xs) * -1)
}


.van-rolling-text-item__item.normal {
    animation: unset !important;
    color: #FAFF00;
    font-size: 46px !important;
    font-family: unset !important;
}
.van-rolling-text-item.normal {
    width: 26px !important;
}

  @media only screen and (max-width: 768px) {
    .van-rolling-text-item.normal {
        width: 24px;
    }
  }