.bg-dark {
    background-color: #0F1923;
}
.bg-secondary {
    background-color: #1A242D;
}
.bg-default {
    background-color: #222E39;
}
.bg-footer {
    background-color: #1B232A;
}
.theme-bg {
    background-color: #0a0a0a;
}
.border-dark {
    border-color: #232C35;
}
.bg-input {
    background-color: #415768;
}

.bg-gradient-light {
    background: linear-gradient(164.95deg, rgba(43, 255, 255, 0.1) 0%, rgba(43, 255, 255, 0.02) 50%, rgba(43, 255, 255, 0.06) 100%);
}

.bg-child-element {
    background: linear-gradient(180deg, #0BBF7B 34.42%, rgba(5, 89, 57, 0) 81.55%), linear-gradient(180deg, #0BBF7B 34.42%, rgba(5, 89, 57, 0) 81.55%);
    opacity: .2;
    position: absolute;
    transform: rotate(56deg);
}

.bg-child-element.one {
    top: -10%;
    left: 0;
    width: 163px;
    height: 386px;
}
.bg-child-element.second {
    right: 0;
    width: 121px;
    height: 1293px;
}
@media (min-width: 768px) {
    .bg-child-element.one {
        top: -16%;
    }
}

.bg-child-element.three {
    top: -73%;
    left: 0;
    width: 230px;
    height: 562px;
}
.bg-child-element.four {
    bottom: -135%;
    right: 28%;
    width: 140px;
    height: 1200px;
}
@media (min-width: 768px) {
    .bg-child-element.three {
        top: -43%;
    }
    .bg-child-element.four {
        bottom: -85%;
    }
}

