.modal-primary {
  display: none;
}

.modal-primary {
  /* padding-top: 48px; */
  padding-bottom: 48px;
  padding-left: 12px;
  padding-right: 12px;
}

.modal-primary.show {
  display: block;
}

.modal-video {
  padding: 0 !important;
}
.modal-dialog-video {
  margin: 0 !important;
  max-width: 100% !important;
}
/* .modal-primary.show .modal-dialog {
  transform: translate(0,0);
} */

.modal-alert-dialog {
  width: 300px;
  max-width: 500px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 50%;
  transform: translate(0px, -50%);
  animation: fade-in .6s linear;
  background-color: #232C35;
}

.modal-dialog {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
  background-color: #232C35;
  animation: fade-in .6s linear;
}

.modal-primary.gradient .modal-dialog {
  background: linear-gradient(90.41deg, #044F32 0.31%, #242D36 99.65%);
}

.close-button {
  transition: all .1s ease-in-out;
}
.close-button:hover {
  opacity: 0.75;
}

.button-signup {
  transition: all .2s ease-in-out;
  background-color: #28AE60 !important;
  border: solid 2px #28AE60 !important;
}
.button-signup:hover {
  background-color: transparent !important;
}
.button-signup:hover > span {
  color: #8F9196 !important;
}

@keyframes modalfadeIn {
  from {
    margin-top: -20%;
  }

  to {
    margin-top: 1.75rem;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin-top: 1.75rem;
    animation: modalfadeIn .6s;
    animation: fade-in .6s linear;
  }
  .modal-dialog.wide {
    max-width: 700px;
    animation: fade-in .6s linear;
  }
  .modal-dialog.wide-xl {
    max-width: 900px;
    animation: fade-in .6s linear;
  }
}

.overlay-closeable {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0, .5);
}