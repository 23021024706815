/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.custom-row {
    display: flex;
    flex-flow: row wrap;
    min-width: 0;
  }
.custom-row::before,
.custom-row::after {
    display: flex;
}
.custom-row-center {
    justify-content: center;
}
@media (min-width: 768px) {
    .custom-col {
        display: block;
        flex: 0 0 14.28571285714%;
        max-width: 14.28571285714%;
    }
}