.shine-1::after {
  content: "";

  /* positioning */
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;

  /* gradient   */
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.03) 1%, rgba(255, 255, 255, 0.6) 30%, rgba(255, 255, 255, 0.85) 50%, rgba(255, 255, 255, 0.85) 70%, rgba(255, 255, 255, 0.85) 71%, rgba(255, 255, 255, 0) 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#00ffffff', GradientType=1);
  /* IE6-9 */

  width: 25%;
  height: 100%;
  transform: skew(-55deg, 0deg);

  /* animating it  */
  animation: shin;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-delay: 2s;
}
.shine-2::after {
  content: "";

  /* positioning */
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;

  /* gradient   */
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.03) 1%, rgba(255, 255, 255, 0.6) 30%, rgba(255, 255, 255, 0.85) 50%, rgba(255, 255, 255, 0.85) 70%, rgba(255, 255, 255, 0.85) 71%, rgba(255, 255, 255, 0) 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#00ffffff', GradientType=1);
  /* IE6-9 */

  width: 25%;
  height: 100%;
  transform: skew(-55deg, 0deg);

  /* animating it  */
  animation: shin;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-delay: 3s;
}
.shine-3::after {
  content: "";

  /* positioning */
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;

  /* gradient   */
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.03) 1%, rgba(255, 255, 255, 0.6) 30%, rgba(255, 255, 255, 0.85) 50%, rgba(255, 255, 255, 0.85) 70%, rgba(255, 255, 255, 0.85) 71%, rgba(255, 255, 255, 0) 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#00ffffff', GradientType=1);
  /* IE6-9 */

  width: 25%;
  height: 100%;
  transform: skew(-55deg, 0deg);

  /* animating it  */
  animation: shin;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-delay: 4s;
}
.shine-4::after {
  content: "";

  /* positioning */
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;

  /* gradient   */
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.03) 1%, rgba(255, 255, 255, 0.6) 30%, rgba(255, 255, 255, 0.85) 50%, rgba(255, 255, 255, 0.85) 70%, rgba(255, 255, 255, 0.85) 71%, rgba(255, 255, 255, 0) 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#00ffffff', GradientType=1);
  /* IE6-9 */

  width: 25%;
  height: 100%;
  transform: skew(-55deg, 0deg);

  /* animating it  */
  animation: shin;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-delay: 5s;
}
.shine-5::after {
  content: "";

  /* positioning */
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;

  /* gradient   */
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.03) 1%, rgba(255, 255, 255, 0.6) 30%, rgba(255, 255, 255, 0.85) 50%, rgba(255, 255, 255, 0.85) 70%, rgba(255, 255, 255, 0.85) 71%, rgba(255, 255, 255, 0) 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#00ffffff', GradientType=1);
  /* IE6-9 */

  width: 25%;
  height: 100%;
  transform: skew(-55deg, 0deg);

  /* animating it  */
  animation: shin;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-delay: 6s;
}
.shine-6::after {
  content: "";

  /* positioning */
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;

  /* gradient   */
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.03) 1%, rgba(255, 255, 255, 0.6) 30%, rgba(255, 255, 255, 0.85) 50%, rgba(255, 255, 255, 0.85) 70%, rgba(255, 255, 255, 0.85) 71%, rgba(255, 255, 255, 0) 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#00ffffff', GradientType=1);
  /* IE6-9 */

  width: 25%;
  height: 100%;
  transform: skew(-55deg, 0deg);

  /* animating it  */
  animation: shin;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-delay: 7s;
}
.shine-7::after {
  content: "";

  /* positioning */
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;

  /* gradient   */
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.03) 1%, rgba(255, 255, 255, 0.6) 30%, rgba(255, 255, 255, 0.85) 50%, rgba(255, 255, 255, 0.85) 70%, rgba(255, 255, 255, 0.85) 71%, rgba(255, 255, 255, 0) 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#00ffffff', GradientType=1);
  /* IE6-9 */

  width: 25%;
  height: 100%;
  transform: skew(-55deg, 0deg);

  /* animating it  */
  animation: shin;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-delay: 8s;
}

@keyframes shin {
  0% {
    left: 0;
    opacity: 0;
  }
  10% {
    opacity: 0.2
  }
  16% {
    opacity: 0.0
  }
  20% {
    left: 82%
  }

  48% {
    opacity: 0.0 
  }

  80% {
    opacity: 0.0
  }

  100% {
    opacity: 0.0
  }
}